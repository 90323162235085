import { h } from 'preact'
import { useMemo } from 'preact/hooks'
import { unique, delve } from '../../lib/util'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'
import { People } from '../../components/people'

export function Tutors(props) {
	const { courses = [] } = props
	const tutors = useMemo(() => {
		return courses
			.map(item => delve(item, 'course.data.tutors', []))
			.flat() // TODO polyfill
			.map(item => delve(item, 'tutor', null))
			.filter(Boolean)
			.filter(unique(item => item.id))
	}, [courses])
	return (
		<div class="slice-tutors">
			<Container>
				<Row>
					<Column size={{ xs: 12, md: 10 }} offset={{ md: 2 }}>
						<People items={tutors} />
					</Column>
				</Row>
			</Container>
		</div>
	)
}
