import { h } from 'preact'
import { Link as WouterLink } from 'wouter-preact'
import { linkResolver } from '../../lib/link-resolver'

export function Link({ children, onClick, ...props }) {
	const href = props.href === null
		? undefined
		: typeof props.href === 'object'
        ? linkResolver(props.href)
        : typeof props.href === 'string'
        ? props.href
		: undefined

    const rel = (props.target || (props.href && props.href.target)) === '_blank' // TODO check name of prismic target field
        ? (props.rel || '') + ' noopener noreferrer'
		: props.rel

	const isExternal = typeof window === 'undefined' || (new URL(href, location.href).host !== location.host)

	if (isExternal) {
		return (
			<a {...props} href={href} rel={rel} onClick={onClick}>{children}</a>
		)
	}

    return (
        <WouterLink href={href} rel={rel} onClick={onClick}>
			<a {...props}>{children}</a>
		</WouterLink>
    )
}
