import { h } from 'preact'
import { useMemo } from 'preact/hooks'
import { linkResolver } from '../../lib/link-resolver'
import { Instagram, Twitter } from '../icons'
import { Link } from '../link'

const linkTypes = [
	{
		type: 'twitter',
		re: /https?:\/\/(www\.)?twitter\.com/
	},
	{
		type: 'instagram',
		re: /https?:\/\/(www\.)?instagram\.com/
	},
	{
		type: 'web',
		re: /.*/
	}
]

function parseLinks(links) {
	const out = []

	links = links
		.map(l => l && l.link)
		.filter(Boolean)
		.filter(l => l.link_type !== 'Any') // Filter out empty links
		.map(linkResolver)

	for (const url of links) {
		const { type } = linkTypes.find(t => t.re.test(url))
		out.push({ type, url })
	}

	return out
}

function getSocialLinks(links) {
	return links.filter(link => link.type !== 'web')
}

function getOtherLinks(links) {
	return links.filter(link => link.type === 'web')
}

export function SocialLinks({ links }) {
	const socialLinks = useMemo(() => getSocialLinks(parseLinks(links)))

	if (!socialLinks || !socialLinks.length) {
		return null
	}

	return (
		<div class="x xw xac">
			{socialLinks.map(link => (
				<div class="pr0-5">
					<a
						class="x xac xjc"
						href={link.url}
						target="_blank"
						rel="nofollow noreferrer"
					>{link.type === 'twitter'
						? <Twitter />
						: link.type === 'instagram'
						? <Instagram />
						: null}</a>
				</div>
			))}
		</div>
	)
}

export function OtherLinks({ links }) {
	const otherLinks = useMemo(() => getOtherLinks(parseLinks(links)))

	if (!otherLinks || !otherLinks.length) {
		return null
	}

	return (
		<ul class="lsn">
			{otherLinks.map(link => (
				<li>
					<Link href={link.url} target="_blank" rel="nofollow noreferrer">{link.url.replace(/https?:\/\/(www)?/, '')}</Link>
				</li>
			))}
		</ul>
	)
}
