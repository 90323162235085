import { h, Fragment } from 'preact'
import { useContent } from '../../lib/content'
import { Meta } from '../../components/meta'
import { HeaderHome } from '../../components/header-home'
import { PageContent } from '../../components/page'

export function Home() {
	const page = useContent({ type: 'home' })

	return (
		<Fragment>
			<Meta page={page} />
			<HeaderHome
				slogan={page.data.header_slogan}
				gallery={page.data.header_gallery}
			/>
			<PageContent page={page} />
		</Fragment>
	)
}
