import { h } from 'preact'
import { RichText, asText } from '../../lib/prismic-render'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'
import { Img } from '../../components/img'
import { Button } from '../../components/button'

import './banner-support.scss'

export function BannerSupport(props) {
	return (
		<div class="slice-banner-support">
			<Container>
				<Row>
					<Column size={{ xs: 6, md: 3 }} offset={{ xs: 3 }}>
						<div class="slice-banner-support__image-wrap">
							<Img class="slice-banner-support__image" {...props.primary.image} />
						</div>
					</Column>
					<Column class="slice-banner-support__content" size={{ xs: 12, md: 4 }}>
						<div class="slice-banner-support__headlines">
							<div class="slice-banner-support__heading">
								{props.primary.heading && !!props.primary.heading.length && (
									<h2 class="slice-banner-support__heading">
										{asText(props.primary.heading)}
									</h2>
								)}
							</div>

							{props.primary.text && !!props.primary.text.length && (
								<RichText richText={props.primary.text} />
							)}
						</div>
						<div class="slice-banner-support__footer">
							<Button href={props.primary.link} large={true}>
								{props.primary.link_text}
							</Button>
						</div>
					</Column>
				</Row>
			</Container>
		</div>
	)
}
