import { h } from 'preact'
import { Row, Column } from '../layout'
import { Link } from '../link'
import { Person } from '../person'

import './people.scss'

export function People(props) {
	return (
		<Row class="people">
			{props.items.map(item => (
				<Column class="people__item" size={{ xs: 6, md: 4 }}>
					<Link href={item}>
						<Person {...item} />
					</Link>
				</Column>
			))}
		</Row>
	)
}
