import { h } from 'preact'
import { Helmet } from 'react-helmet-async'
import { asText } from '../../lib/prismic-render'
import { linkResolver } from '../../lib/link-resolver'
import { useContent } from '../../lib/content'

// TODO change defaults after going live
// TODO handle URL root properly

export function Meta(props) {
	const { data: settings } = useContent({ type: 'settings' })

	const page = props
		&& props.page

	const data = page
		&& page.data

	const title = (data && data.meta_title)
		|| (data && data.title && !!data.title.length && asText(data.title))
		|| (settings && settings.meta_title)
		|| ''

	const description = (data && data.meta_description)
		|| (settings && settings.meta_description)
		|| ''

	const url = (page && `https://catalyst-development.createdbymad.tech/${linkResolver(page)}`)
		|| 'https://catalyst-development.createdbymad.tech'

	const image = (data && data.meta_image && data.meta_image.url)
		|| (settings && settings.meta_image && settings.meta_image.url)
		|| 'https://catalyst-development.createdbymad.tech/social.jpg'

	return (
		<Helmet>
			<title>{title} | Catalyst</title>

			<meta name="description" content={description} />

			<meta property="og:type" content="website" />
			<meta property="og:title" content={title} />
			<meta property="og:url" content={url} />
			<meta property="og:description" content={description} />
			<meta property="og:image" content={image} />

			<meta name="twitter:card" content="summary_large_image" />
			<meta name="twitter:title" content={title} />
			<meta name="twitter:url" content={url} />
			<meta name="twitter:description" content={description} />
			<meta name="twitter:image" content={image} />
		</Helmet>
	)
}
