import { h } from 'preact'
import { RichText, asText } from '../../lib/prismic-render'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'
import './smallprint.scss'

function SmallPrintItem(props) {
	return (
		<div class="slice-grid-smallprint-item">
			{/* TODO use FP2 component */}
			{props.heading && !!props.heading.length && (
				<h3 class="fp2">
					{asText(props.heading)}
				</h3>
			)}
			{/* TODO use FP2 component */}
			<div class="fp2">
				<RichText richText={props.text} />
			</div>
		</div>
	)
}

export function Smallprint(props) {
	return (
		<div class="slice-grid-smallprint">
			<Container>
				<Row>
					<Column size={{ xs: 12, md: 8 }} offset={{ md: 3 }}>
						{/* TODO create component (or mixin) for .body */}
						<h3 class="slice-grid-smallprint__heading body">
							{props.primary.heading && asText(props.primary.heading)}
						</h3>
						<Row>
							{props.items.map(item => (
								<Column size={{ xs: 6, md: 3 }}>
									<SmallPrintItem {...item} />
								</Column>
							))}
						</Row>
					</Column>
				</Row>
			</Container>
		</div>
	)
}
