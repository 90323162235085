import { html } from "htm/preact";
import { Switch, Route, Link, useLocation, useRoute } from "wouter-preact";
import { FormContext, Form } from "./form.js";

import "./form.scss";

export function PaymentForm(props) {
	const { formId = '3765589' } = props
	const [, { uid }] = useRoute("/payments/:uid");

	return html`
		<${FormContext} id=${formId}>
			${ctx => {
				const {
					id,
					loading,
					error,
					fields,
					//sections,
					values,
					setValue,
					setValues,
					saveValues,
					serialize
				} = ctx;

				const [, go] = useLocation();

				if (loading) return html`
					<div class="l-container">
						<div class="l-row">
							<div class="l-col12 l-col8@md l-off3@md">
								<pre>Loading</pre>
							</div>
						</div>
					</div>
				`;

				async function handleSubmit(event) {
					event.preventDefault();

					const body = await serialize();

					fetch(`/api/form/${id}/submission`, {
						method: "POST",
						mode: "cors",
						cache: "no-cache",
						credentials: "include",
						headers: {
							"Accept": "application/json",
							"Content-Type": "application/x-www-form-urlencoded"
						},
						body
					});

					go(`/payments/${uid}/thank-you`);
				}

				return html`
					<div class="l-container u-mb96">
						<div class="l-row">
							<div class="l-col12 l-col8@md l-off3@md">
								<${Form}
									fields=${fields}
									values=${values}
									setValue=${setValue}
									setValues=${setValues}
									saveValues=${saveValues}
									onSubmit=${handleSubmit}
									method="POST"
									action="/form/${id}/submission"
									enctype="application/x-www-form-urlencoded"
								/>
							</div>
						</div>
					</div>
				`
			}}
		<//>
	`;
}
