import { h, Fragment } from 'preact'
import { useState, useContext } from 'preact/hooks'
import { Router, Switch, Route } from 'wouter-preact'
import locationHook from 'wouter-preact/use-location'
import staticLocationHook from 'wouter-preact/static-location'

import { prismicEndpoint } from './config'
import { getApi } from './lib/prismic-api'
import { StoreCtx, ApiCtx } from './lib/context'
import { fetchData } from './lib/fetch'
import { useBackgroundColor, useDiffBlack, useSection } from './lib/section'

import { Helmet } from 'react-helmet-async'

import { Header, MiniNav } from './components/nav'
import { HeaderMobile } from './components/nav-mobile'
import { Page } from './components/page'

import { News } from './components/news'
import { Footer } from './components/footer'
import { CookieBar } from './components/cookie-bar'

import { Home } from './views/home'
import { Apply } from './views/apply'
import { Payment } from './views/payments'
import { Team } from './views/team'

import { DebugGrid, DebugOutline } from './components/debug'

function dynamicLocationHook(options) {
	const [path, navigate] = locationHook(options)
	const apiRef = useContext(ApiCtx)
	const [, setStore] = useContext(StoreCtx)

	function _navigate(to, replace) {
		(apiRef.v || (apiRef.v = getApi(prismicEndpoint)))
			.then(api => fetchData(api, to))
			.then(data => {
				setStore(prev => prev.concat(data))
				navigate(to, replace)
				window.scrollTo(0, 0)
			})
	}

	return [path, _navigate]
}

function AppUI() {
	const section = useSection()
	const bgc = useBackgroundColor()
	const diffblack = useDiffBlack()

	return (
		<>
			<Helmet>
				<body class={`s-section--${section || 'general'}`} style={`--background-color: var(--${bgc}); --diffblack: var(--${diffblack})`} />
			</Helmet>

			<div class="dn lg-db">
				<Header />
				<MiniNav />
			</div>

			<div class="lg-dn">
				<HeaderMobile />
			</div>

			<main>
				<Switch>
					<Route path="/">
						<Home />
					</Route>
					<Route path="/about">
						<Page selector={{ type: 'about' }} />
					</Route>
					<Route path="/apply/thank-you/:id?">
						<Apply />
					</Route>
					<Route path="/apply/:sectionIndex?">
						<Apply />
					</Route>
					<Route path="/payments/:uid">
						{params => <Payment uid={params.uid} />}
					</Route>
					<Route path="/payments/:uid/thank-you">
						{params => <Payment uid={params.uid} />}
					</Route>
					<Route path="/team">
						<Team />
					</Route>
					<Route path="/pages/:page">
						{params => <Page selector={{ type: 'page', uid: params.page }} />}
					</Route>
					<Route path="/stories/school/:school">
						{params => <News selector={{ type: 'news', school: params.school }} />}
					</Route>
					<Route path="/stories/search/:term">
						{params => <News selector={{ type: 'news', term: params.term }} />}
					</Route>
					<Route path="/stories">
						{params => <News selector={{ type: 'news' }} />}
					</Route>
					<Route path="/stories/:article">
						{params => <Page selector={{ type: 'article', uid: params.article }} />}
					</Route>
					<Route path="/:school">
						{params => <Page selector={{ type: 'school', uid: params.school }} />}
					</Route>
					<Route path="/:school?/people/:person">
						{params => <Page selector={{ type: 'person', uid: params.person }} />}
					</Route>
					<Route path="/:school/topic/:topic">
						{params => <Page selector={{ type: 'topic', uid: params.topic }} />}
					</Route>
					<Route path="/:school/course/:course">
						{params => <Page selector={{ type: 'course', uid: params.course }} />}
					</Route>
				</Switch>
			</main>

			<Footer />
			<CookieBar />

			{process.env.NODE_ENV !== 'production' && (
				<>
					<DebugGrid />
					<DebugOutline />
				</>
			)}
		</>
	)
}

export function App(props) {
	const store = useState(props.data)
	const hook = process.browser
		? dynamicLocationHook
		: staticLocationHook(props.url)

	return (
		<StoreCtx.Provider value={store}>
			<Router hook={hook}>
				<AppUI />
			</Router>
		</StoreCtx.Provider>
	)
}
