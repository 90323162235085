import { h } from 'preact'

export function Minilogo() {
	return (
		<svg width="37" height="35" viewBox="0 0 37 35" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M26.5849 23.4097C25.0814 27.7618 20.4518 30.2356 15.9176 28.2428C11.6458 26.3645 9.71284 21.875 9.7367 17.5916C9.7367 13.2624 11.6458 8.74998 15.9653 6.8488C20.4518 4.90181 24.7951 7.32982 26.4894 11.4987C26.5133 11.5674 35.9397 11.4758 35.9636 11.4987C34.7703 6.09291 30.7611 2.0615 25.2008 0.687154C21.0961 -0.3207 15.9653 -0.229077 11.9561 1.03074C0.620541 4.53532 -3.41252 18.6224 3.17402 27.8534C6.65821 32.7552 12.4811 34.9313 18.4949 35C18.9722 35 19.4733 35.0229 19.9506 35C27.8497 34.9084 34.2931 31.106 36.059 23.4097C36.0352 23.4097 26.6088 23.3409 26.5849 23.4097ZM19.8313 29.0674C19.8552 29.0674 19.8552 29.0674 19.879 29.0674C19.879 29.0674 19.8552 29.0674 19.8313 29.0674Z" fill="#090609"/>
		</svg>
	)
}

export function Slash(props) {
	return (
		<svg {...props} width="20" height="35" viewBox="0 0 20 35" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M19.7586 0L6.13207 35H0.619415L14.1982 0H19.7586Z" fill="#090609"/>
		</svg>
	)
}

export function Logo() {
	return (
		<svg style="display: block; width: 80px; height: auto" width="305" height="133" viewBox="0 0 305 133" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M97.5313 88.9568C92.0156 105.495 75.0309 114.895 58.3963 107.323C42.7248 100.185 35.6332 83.125 35.7208 66.8482C35.7208 50.3973 42.7248 33.25 58.5714 26.0255C75.0309 18.627 90.965 27.8534 97.1811 43.695C97.2686 43.9562 131.851 43.608 131.939 43.695C127.561 23.1532 112.853 7.83378 92.4534 2.61127C77.3947 -1.21857 58.5714 -0.870406 43.8629 3.9169C2.27655 17.2343 -12.5195 70.7651 11.6444 105.843C24.4268 124.47 45.7891 132.739 67.8517 133C69.6027 133 71.4413 133.087 73.1923 133C102.171 132.652 125.81 118.203 132.289 88.9568C132.201 88.9568 97.6188 88.6957 97.5313 88.9568ZM72.7546 110.456C72.8421 110.456 72.8421 110.456 72.9297 110.456C72.9297 110.456 72.8421 110.456 72.7546 110.456Z" fill="currentColor"/>
			<path d="M222.903 0L172.912 133H152.688L202.504 0H222.903Z" fill="currentColor"/>
			<path d="M263.702 0L213.71 133H193.486L243.215 0H263.702Z" fill="currentColor"/>
			<path d="M304.5 0L254.508 133H234.284L284.1 0H304.5Z" fill="currentColor"/>
		</svg>
	)
}

export function Logotype(props) {
	return (
		<svg {...props} width="900" height="223" viewBox="0 0 900 223" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M97.2869 133.475C91.7467 150.095 74.8667 159.53 58.2463 151.913C42.5781 144.815 35.5664 127.676 35.5664 111.229C35.5664 94.6965 42.4915 77.471 58.3328 70.2865C74.7801 62.8424 90.6214 72.1043 96.854 88.0314C96.9406 88.291 131.48 87.9448 131.48 88.0314C127.238 67.5166 112.609 52.1089 92.1796 46.8287C77.2039 42.9335 58.4194 43.3663 43.7034 48.1271C2.23905 61.544 -12.4769 115.211 11.588 150.355C24.3996 169.052 45.6079 177.275 67.6818 177.621C69.4996 177.621 71.2309 177.708 73.0488 177.621C101.961 177.275 125.507 162.733 131.999 133.475C131.913 133.475 97.3734 133.216 97.2869 133.475ZM72.616 155.115C72.7025 155.115 72.7025 155.115 72.7891 155.115C72.7025 155.029 72.616 155.029 72.616 155.115Z" fill="currentColor"/>
			<path d="M486.307 174.159C486.307 174.159 483.797 167.147 483.797 128.888C483.797 117.029 484.143 105.17 483.71 93.3116C483.191 76.6921 477.564 59.9859 462.415 51.3299C448.565 43.453 429.781 41.9814 413.939 45.011C391.952 49.1659 374.033 63.7946 372.302 86.5599C372.302 86.5599 376.024 86.5599 376.111 86.5599H403.638C403.898 86.5599 407.447 86.6465 407.447 86.5599C407.447 77.0383 414.026 66.3914 428.742 66.3914C444.15 66.3914 449.344 75.4802 448.305 85.8674C447.267 96.3412 434.628 97.6396 426.231 99.0246C418.614 100.323 410.909 100.929 403.292 102.487C385.546 105.949 367.021 114.259 364.338 134.255C361.135 158.578 380.785 175.457 403.638 177.102C419.999 178.314 437.831 176.236 448.825 164.724C449.084 168.273 449.257 170.264 449.777 174.159C449.95 174.332 486.307 174.159 486.307 174.159ZM449.257 112.182C449.257 122.05 448.565 131.917 443.458 140.66C436.186 153.298 418.787 161.694 405.889 151.653C400.435 147.412 398.098 139.968 400.608 133.476C403.205 126.724 410.563 123.867 416.969 121.963C427.097 119.193 440.428 118.501 449.257 112.182Z" fill="currentColor"/>
			<path d="M541.969 0H506.132V174.159H541.969V0Z" fill="currentColor"/>
			<path d="M654.501 48.0403C654.501 48.0403 635.543 105.43 626.541 134.254C616.413 105.43 595.637 48.0403 595.637 48.0403H558.328C572.351 84.7418 586.981 121.27 601.177 157.885C603.601 164.204 608.881 175.803 607.496 180.045C604.899 187.835 604.64 188.354 602.043 193.548C599.359 198.915 594.252 200.126 589.058 200.473H574.775V222.978C574.775 222.978 601.956 223.065 605.852 222.892C628.618 221.68 634.764 203.156 641.516 184.632C651.038 158.491 691.897 48.0403 691.897 48.0403H654.501Z" fill="currentColor"/>
			<path d="M763.66 99.977C755.523 97.9861 742.452 95.649 738.556 86.993C733.362 75.7402 740.72 66.5648 754.571 66.5648C766.776 66.5648 772.749 74.7014 774.827 85.9543C774.827 86.1274 809.193 85.9543 809.193 85.9543C806.423 63.5352 790.841 48.5603 768.594 45.4441C751.627 43.0204 731.977 44.4054 717.781 54.706C699.429 68.0363 697.005 95.0431 715.963 108.979C725.658 116.164 737.604 118.761 749.117 121.53C757.168 123.435 770.239 125.079 774.48 133.389C780.367 144.988 768.421 154.683 757.774 155.635C745.135 156.674 736.825 147.152 734.488 135.726C734.488 135.553 698.91 135.726 698.91 135.726C701.333 156.501 714.751 171.129 735.094 175.804C749.204 179.093 766.69 178.92 780.453 174.678C799.238 168.965 814.733 152.952 811.097 132.091C807.202 109.326 782.271 104.565 763.66 99.977Z" fill="currentColor"/>
			<path d="M328.846 70.0275H353.517V48.0412H328.846V6.05954H321.315L293.268 20.6016V48.0412H273.791V70.0275H293.268C293.268 70.0275 293.095 112.269 293.268 133.39C293.355 147.758 296.904 163.339 311.014 170.264C321.921 175.631 335.252 174.159 347.111 174.159C347.285 174.159 350.92 174.159 350.92 174.159V152.433C350.92 152.346 347.198 152.433 347.111 152.433C342.87 152.433 339.84 152.779 336.204 150.615C330.924 147.412 329.539 140.747 329.019 135.034C328.5 128.975 328.846 122.743 328.846 116.683V70.0275Z" fill="currentColor"/>
			<path d="M265.134 174.159C265.134 174.159 262.623 167.147 262.623 128.888C262.623 117.029 262.969 105.17 262.537 93.3116C262.017 76.6921 256.391 59.9859 241.242 51.3299C227.391 43.453 208.607 41.9814 192.766 45.011C170.778 49.1659 152.859 63.7946 151.128 86.5599C151.128 86.5599 154.85 86.5599 154.937 86.5599H182.464C182.724 86.5599 186.273 86.6465 186.273 86.5599C186.273 77.0383 192.852 66.3914 207.568 66.3914C222.977 66.3914 228.171 75.4802 227.132 85.8674C226.093 96.3412 213.455 97.6396 205.058 99.0246C197.44 100.323 189.736 100.929 182.118 102.487C164.372 105.949 145.848 114.259 143.164 134.255C139.961 158.578 159.611 175.457 182.464 177.102C198.825 178.314 216.657 176.236 227.651 164.724C227.911 168.273 228.084 170.264 228.603 174.159C228.776 174.332 265.134 174.159 265.134 174.159ZM228.084 112.182C228.084 122.05 227.391 131.917 222.284 140.66C215.013 153.298 197.613 161.694 184.715 151.653C179.262 147.412 176.924 139.968 179.435 133.476C182.032 126.724 189.39 123.867 195.795 121.963C205.837 119.193 219.168 118.501 228.084 112.182Z" fill="currentColor"/>
			<path d="M875.329 70.0275H900V48.0412H875.329V6.05954H867.798L839.665 20.6016V48.0412H820.188V70.0275H839.665C839.665 70.0275 839.491 112.269 839.665 133.39C839.751 147.758 843.3 163.339 857.41 170.264C868.317 175.631 881.648 174.159 893.508 174.159C893.681 174.159 897.317 174.159 897.317 174.159V152.433C897.317 152.346 893.594 152.433 893.508 152.433C889.266 152.433 886.236 152.779 882.601 150.615C877.32 147.412 875.935 140.747 875.416 135.034C874.896 128.975 875.243 122.743 875.243 116.683V70.0275H875.329Z" fill="currentColor"/>
		</svg>
	)
}

export function LogotypeSmall(props) {
	return (
		<svg {...props} width="241" height="60" viewBox="0 0 241 60" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M26.0513 35.9127C24.5677 40.3843 20.0476 42.9229 15.5971 40.8734C11.4015 38.9636 9.52388 34.3522 9.52388 29.9272C9.52388 25.4789 11.3783 20.8442 15.6202 18.9112C20.0244 16.9082 24.2664 19.4002 25.9354 23.6855C25.9585 23.7554 35.2074 23.6623 35.2074 23.6855C34.0716 18.1659 30.1541 14.0203 24.6836 12.5996C20.6735 11.5516 15.6434 11.668 11.7028 12.949C0.599567 16.5589 -3.34104 30.9985 3.10301 40.4541C6.53366 45.4847 12.2128 47.6973 18.1237 47.7904C18.6105 47.7904 19.0741 47.8137 19.5608 47.7904C27.303 47.6973 33.608 43.7846 35.3465 35.9127C35.3233 35.9127 26.0744 35.8428 26.0513 35.9127ZM19.4449 41.7351C19.4681 41.7351 19.4681 41.7351 19.4913 41.7351C19.4681 41.7118 19.4449 41.7118 19.4449 41.7351Z" fill="currentColor"/>
			<path d="M130.222 46.8589C130.222 46.8589 129.55 44.9724 129.55 34.6783C129.55 31.4876 129.643 28.2969 129.527 25.1063C129.388 20.6346 127.881 16.1397 123.825 13.8107C120.116 11.6914 115.086 11.2954 110.844 12.1106C104.956 13.2285 100.158 17.1645 99.6941 23.2897C99.6941 23.2897 100.691 23.2897 100.714 23.2897H108.085C108.155 23.2897 109.105 23.3129 109.105 23.2897C109.105 20.7278 110.867 17.8631 114.808 17.8631C118.934 17.8631 120.324 20.3086 120.046 23.1033C119.768 25.9214 116.384 26.2707 114.135 26.6434C112.095 26.9927 110.032 27.1557 107.993 27.575C103.241 28.5066 98.2801 30.7424 97.5616 36.1223C96.7039 42.6667 101.966 47.2082 108.085 47.6507C112.466 47.9768 117.241 47.4178 120.185 44.3203C120.255 45.2752 120.301 45.8108 120.44 46.8589C120.487 46.9054 130.222 46.8589 130.222 46.8589ZM120.301 30.1834C120.301 32.8384 120.116 35.4935 118.748 37.8457C116.801 41.246 112.142 43.5051 108.688 40.8035C107.228 39.6623 106.602 37.6594 107.274 35.9127C107.969 34.0961 109.94 33.3275 111.655 32.8151C114.367 32.0699 117.937 31.8836 120.301 30.1834Z" fill="currentColor"/>
			<path d="M145.127 0H135.531V46.8589H145.127V0Z" fill="currentColor"/>
			<path d="M175.261 12.9257C175.261 12.9257 170.184 28.3667 167.774 36.1222C165.062 28.3667 159.498 12.9257 159.498 12.9257H149.508C153.263 22.8005 157.18 32.6288 160.982 42.4803C161.631 44.1805 163.045 47.3013 162.674 48.4425C161.979 50.5386 161.909 50.6783 161.214 52.0757C160.495 53.5197 159.127 53.8457 157.737 53.9389H153.912V59.9942C153.912 59.9942 161.191 60.0175 162.234 59.9709C168.33 59.6448 169.976 54.6608 171.784 49.6768C174.334 42.6433 185.275 12.9257 185.275 12.9257H175.261Z" fill="currentColor"/>
			<path d="M204.491 26.8997C202.312 26.364 198.812 25.7352 197.769 23.4062C196.378 20.3785 198.348 17.9098 202.057 17.9098C205.326 17.9098 206.925 20.0991 207.481 23.1267C207.481 23.1733 216.684 23.1267 216.684 23.1267C215.942 17.0947 211.77 13.0656 205.812 12.2271C201.269 11.575 196.007 11.9477 192.206 14.7191C187.292 18.3057 186.643 25.5721 191.719 29.3218C194.315 31.2548 197.514 31.9535 200.597 32.6988C202.753 33.2112 206.253 33.6537 207.389 35.8895C208.965 39.0103 205.766 41.6188 202.915 41.8749C199.531 42.1544 197.305 39.5926 196.679 36.5183C196.679 36.4717 187.152 36.5183 187.152 36.5183C187.802 42.1078 191.394 46.0438 196.842 47.3015C200.62 48.1865 205.302 48.1399 208.988 46.9987C214.018 45.4616 218.167 41.153 217.194 35.5401C216.151 29.4149 209.475 28.134 204.491 26.8997Z" fill="currentColor"/>
			<path d="M88.0577 18.8415H94.6641V12.9259H88.0577V1.63037H86.0411L78.5307 5.54304V12.9259H73.3152V18.8415H78.5307C78.5307 18.8415 78.4844 30.2069 78.5307 35.8896C78.5539 39.7557 79.5043 43.9478 83.2827 45.811C86.2033 47.2549 89.7731 46.859 92.9487 46.859C92.9951 46.859 93.9687 46.859 93.9687 46.859V41.0133C93.9687 40.99 92.9719 41.0133 92.9487 41.0133C91.8129 41.0133 91.0016 41.1065 90.028 40.5242C88.6141 39.6625 88.2432 37.8692 88.1041 36.3321C87.965 34.7018 88.0577 33.0249 88.0577 31.3946V18.8415Z" fill="currentColor"/>
			<path d="M70.9969 46.8588C70.9969 46.8588 70.3247 44.9724 70.3247 34.6783C70.3247 31.4876 70.4174 28.2969 70.3015 25.1063C70.1624 20.6346 68.6557 16.1397 64.5992 13.8107C60.8904 11.6914 55.8603 11.2954 51.6184 12.1106C45.7306 13.2285 40.9324 17.1645 40.4688 23.2897C40.4688 23.2897 41.4655 23.2897 41.4887 23.2897H48.8599C48.9295 23.2897 49.8799 23.3129 49.8799 23.2897C49.8799 20.7278 51.6415 17.8631 55.5821 17.8631C59.7082 17.8631 61.099 20.3086 60.8208 23.1033C60.5427 25.9214 57.1584 26.2707 54.9099 26.6434C52.8701 26.9927 50.8071 27.1557 48.7672 27.575C44.0153 28.5066 39.0548 30.7424 38.3362 36.1223C37.4785 42.6667 42.7404 47.2082 48.8599 47.6507C53.241 47.9768 58.0161 47.4178 60.9599 44.3203C61.0295 45.2752 61.0758 45.8108 61.2149 46.8588C61.2613 46.9054 70.9969 46.8588 70.9969 46.8588ZM61.0758 30.1834C61.0758 32.8384 60.8904 35.4935 59.5228 37.8457C57.5756 41.246 52.9164 43.5051 49.4626 40.8035C48.0023 39.6623 47.3764 37.6594 48.0486 35.9127C48.744 34.0961 50.7143 33.3275 52.4297 32.8151C55.1185 32.0699 58.6883 31.8836 61.0758 30.1834Z" fill="currentColor"/>
			<path d="M234.394 18.8415H241V12.9259H234.394V1.63037H232.377L224.844 5.54304V12.9259H219.628V18.8415H224.844C224.844 18.8415 224.797 30.2069 224.844 35.8896C224.867 39.7557 225.817 43.9478 229.595 45.811C232.516 47.2549 236.086 46.859 239.261 46.859C239.308 46.859 240.281 46.859 240.281 46.859V41.0133C240.281 40.99 239.285 41.0133 239.261 41.0133C238.126 41.0133 237.314 41.1065 236.341 40.5242C234.927 39.6625 234.556 37.8692 234.417 36.3321C234.278 34.7018 234.371 33.0249 234.371 31.3946V18.8415H234.394Z" fill="currentColor"/>
		</svg>
	)
}
