import { h } from 'preact'
import { HeaderStandard } from './standard'
import { HeaderHome } from './home'

export function Header(props) {
	switch (props.primary.style) {
		case 'home':
			return <HeaderHome {...props} />
		default:
			return <HeaderStandard {...props} />
	}
}
