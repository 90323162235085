import { h } from 'preact'
import { Link } from '../link'
import { Dropdown } from './dropdown'
import { useContent } from '../../lib/content'
import { linkResolver } from '../../lib/link-resolver'

import './menu.scss'

export function Menu() {
	const { data: settings } = useContent({ type: 'settings' })
	const menuItems = settings.menu_desktop || []

	return (
		<ul class="menu fp2">
			<Dropdown />
			{menuItems.map(item => (
				<li><Link href={linkResolver(item.page)}>{item.label}</Link></li>
			))}
			<li><Link class="button" href="/apply">Apply</Link></li>
		</ul>
	)
}
