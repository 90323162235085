import { h } from 'preact'
import cx from 'clsx'
import './column.scss'

export function Column({ size = 12, offset = 0, gutter = true, class: className, children }) {
	const sizes = typeof size === 'object'
		? Object.entries(size).map(([k, v]) => `column--${k}-${v}`)
		: [`column--xs-${size}`]

	const offsets = typeof offset === 'object'
		? Object.entries(offset).map(([k, v]) => `column--${k}-offset-${v}`)
		: offset === 0
			? []
			: [`column--xs-offset-${offset}`]

	return (
		<div class={cx('column', ...sizes, ...offsets, !gutter && 'column--no-gutter', className)}>
			{children}
		</div>
	)
}
