import { h, Fragment } from 'preact'
import { useCallback } from 'preact/hooks'
import { useInView } from 'react-intersection-observer'
import { asText } from '../../lib/prismic-render'
import { anchorify } from '../../lib/anchorify'
import { Container } from '../container'
import { Row, Column } from '../layout'
import cx from 'clsx'

import './page-menu.scss'

export function PageMenu(props) {
	const body = props.page
		&& props.page.data
		&& props.page.data.body
		|| []

	const headings = body.filter(slice => {
		return slice.slice_type === 'anchor'
	})

	if (!headings || !headings.length) {
		return null
	}

	const [ref, atTop] = useInView()

	return (
		<Fragment>
			<div class="page-menu__sentinel" ref={ref} />
			<div class={cx('page-menu', {
				['page-menu--visible']: !atTop
			})}>
				<Container class="page-menu__inner">
					<Row>
						<Column size={{ xs: 12, md: 2 }}>
							<ol class="page-menu__list">
								{headings.map(slice => (
									<PageMenuItem {...slice} />
								))}
							</ol>
						</Column>
					</Row>
				</Container>
			</div>
		</Fragment>
	)
}

function PageMenuItem(props) {
	const anchor = props.primary
		&& props.primary.anchor
		&& anchorify(props.primary.anchor)
		|| ''

	const handleClick = useCallback((e) => {
		if (e) {
			e.preventDefault()
		}

		const el = document.getElementById(anchor)

		if (el) {
			el.scrollIntoView({
				behavior: 'smooth',
				block: 'start',
				inline: 'center'
			})
		}
	}, [anchor])

	return (
		<li class="page-menu__list-item">
			<a class="page-menu__link fp2" href={`#${anchor}`} onClick={handleClick}>
				{asText(props.primary.heading)}
			</a>
		</li>
	)
}
