import { h } from 'preact'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'
import { AccordionRow } from './accordion-row'

export function Accordion(props) {
	return (
		<Container>
			<Row>
				<Column size={{ xs: 12, md: 8 }} offset={{ md: 3 }}>
					{props.items.map(AccordionRow)}
				</Column>
			</Row>
		</Container>
	)
}
