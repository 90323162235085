import { h } from 'preact'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'
import { Img } from '../../components/img'
import { Embed } from '../../components/embed'
import { Logotype } from '../../components/logo'

import './home.scss'

export function HeaderHome(props) {
	return (
		<header class="slice-header-home">
			<Container>
				<div class="slice-header-home__wrap">
					<div class="slice-header-home__text-wrap">
						<div class="slice-header-home__text">
							<Row>
								<Column size={{ xs: 12, md: 8 }}>
									<h1>
										<Logotype class="slice-header-home__logo" />
									</h1>
								</Column>
							</Row>
							<Row>
								<Column size={{ xs: 12, md: 4 }} offset={{ md: 1 }}>
									{/* TODO typography component */}
									<p class="h3">Institute for Creative Arts and Technology</p>
								</Column>
							</Row>
						</div>
					</div>
					<div class="slice-header-home__image">
						<Row>
							<Column size={{ xs: 12, md: 7 }} offset={{ md: 5 }}>
								{(props.primary.embed && props.primary.embed.html)
									? <Embed {...props.primary.embed} />
									: <Img {...props.primary.image} />
								}
							</Column>
						</Row>
					</div>
				</div>
			</Container>
		</header>
	)
}
