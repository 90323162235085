import { h } from 'preact'
import { asText, RichText } from '../../lib/prismic-render'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'
import { Link } from '../../components/link'
import { Img } from '../../components/img'
import { Person } from '../../components/person'

import './team.scss'

export function Team(props) {
	return (
		<div class="slice-team">
			<Container>
				<Row>
					<Column size={{ xs: 12, md: 10 }} offset={{ md: 2 }}>
						<Row>
							{props.items.map(item => (
								<Column class="slice-team__item" size={{ xs: 6, md: 4 }}>
									{(item && item.person && item.person.data)
										? (
											<Link href={item.person}>
												<Person {...item.person} />
											</Link>
										)
										: (
											<OldPerson {...item} />
										)
									}
								</Column>
							))}
						</Row>
					</Column>
				</Row>
			</Container>
		</div>
	)
}

// TODO remove this once all content is switched to new format
// TODO after that also switch to using <People /> here

function OldPerson(props) {
	return (
		<div class="slice-team-person">
			<div class="slice-team-person__image-wrap">
				<Img class="slice-team-person__image" sizeHint={30} {...props.image} />
			</div>
			<div class="slice-team-person__text">
				<p class="slice-team-person__name">
					{asText(props.name)}
				</p>
				<div class="fp2">
					<RichText richText={props.bio} />
				</div>
			</div>
		</div>
	)
}
