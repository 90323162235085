import { h } from 'preact'
import { useState, useEffect, useCallback } from 'preact/hooks'
import { useInView } from 'react-intersection-observer'
import { useInterval } from '../../lib/use-interval'
import { asText } from '../../lib/prismic-render'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'
import { Img } from '../../components/img'
import cx from 'clsx'
import './list.scss'

export function List(props) {
	const [index, setIndex] = useState(0)
	const [delay, setDelay] = useState(null)
	const [ref, visible] = useInView({ triggerOnce: true })

	useInterval(() => {
		setIndex(i => (i + 1) % props.items.length)
	}, delay)

	useEffect(() => {
		if (visible) {
			setDelay(3000)
		}
	}, [visible])

	const goto = useCallback(idx => {
		setDelay(null)
		setIndex(idx)
	})

	return (
		<div ref={ref} class="slice-gallery-list">
			<Container>
				<Row>
					<Column size={{ xs: 12, lg: 7 }}>
						<div class="slice-gallery-list__image-wrap">
							<Img class="slice-gallery-list__image" {...props.items[index].image} />
						</div>
					</Column>
					<Column size={{ xs: 11, lg: 5 }} offset={{ xs: 1, lg: 0 }}>
						<ul class="slice-gallery-list__list">
							{props.items.map((item, idx) => (
								<li class={cx('slice-gallery-list__list-item', (index === idx) && 'slice-gallery-list__list-item--active')}>
									<button class="slice-gallery-list__button" onClick={() => goto(idx)}>
										{item.heading && asText(item.heading)}
									</button>
								</li>
							))}
						</ul>
					</Column>
				</Row>
			</Container>
		</div>
	)
}
