import { h } from 'preact'
import { Img } from '../../components/img'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'

export function Image(props) {
	const large = props.primary.size === 'large'

	return (
		<div class="slice-image">
			<Container>
				<Row>
					<Column size={{ xs: 12, md: large ? 12 : 8 }} offset={{ md: large ? 0 : 3 }} >
						<Img {...props.primary.image} />
					</Column>
				</Row>
			</Container>
		</div>
	)
}
