import { useLocation } from 'wouter-preact'

export function useSection() {
	const [location] = useLocation()
	const matches = location.match(/^\/(music|film|acting)/)
	if (matches) return matches[1]
	return null
}

export function useBackgroundColor() {
	return useSection() || 'white'
}

export function useDiffBlack() {
	return useSection() || '#FEFEFE'
}
