import { h, Fragment } from 'preact'
import { Switch, Route, useLocation } from 'wouter-preact'
import { useContent } from '../../lib/content'
import { Meta } from '../../components/meta'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'
import { PageContent } from '../../components/page'
import { RichText } from '../../lib/prismic-render'
import { PaymentForm } from '../../components/forms/payment-form'

export function Payment(props) {
	const [, go] = useLocation()
	const page = useContent({ type: 'payment', uid: props.uid })
	const { data } = page
	const { formstack_id, thank_you_message } = data

	return (
		<Fragment>
			<Meta page={page} />

			<Switch>
				<Route path="/payments/:uid/thank-you">
					<div class="l-container">
						<div class="l-row u-mb96">
							<div class="l-col12 l-col8@md l-off3@md">
								{thank_you_message && !!thank_you_message.length && (
									<article class="copy">
										<RichText richText={thank_you_message} />
										<p>
											<a class="button" href="javascript:void(0)" data-cb-type="checkout" data-cb-plan-id="enrolment-fee" >Pay here</a>
										</p>
									</article>
								)}
							</div>
						</div>
					</div>
				</Route>
				<Route path="/payments/:uid">
					<PaymentForm formId={formstack_id} />
				</Route>
			</Switch>

			<PageContent page={page} />
		</Fragment>
	)
}
