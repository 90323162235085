import { h } from 'preact'
import { Accordion } from './accordion'
import { Faq } from './faq'

export function Expander(props) {
	switch (props.primary.style) {
		case 'grid':
			return <Faq {...props} />
		case 'accordion':
		default:
			return <Accordion {...props} />
	}
}
