import { h } from 'preact'
import { RichText, asText } from '../../lib/prismic-render'
import { Img } from '../../components/img'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'
import { Button } from '../../components/button'
import './text-image.scss'

export function TextImageGrid(props) {
	return (
		<div class="slice-grid-text-image">
			<Container>
				{props.items.map(TextImageGridItem)}
			</Container>
		</div>
	)
}

function TextImageGridItem(props) {
	return (
		<Row class="slice-grid-text-image-item">
			<Column size={{ xs: 12, md: 6, xl: 4 }}>
				{/* TODO use copy component or mixin */}
				<div class="copy u-mx12@md u-mx24@lg">
					<h2 class="slice-grid-text-image-item__heading">
						{asText(props.heading, null)}
					</h2>
					<div class="f-p2">
						<RichText richText={props.text} />
					</div>
					{props.link && props.link_text && (
						<div class="slice-grid-text-image-item__button-wrap">
							<Button href={props.link}>
								{props.link_text}
							</Button>
						</div>
					)}
				</div>
			</Column>
			<Column size={{ xs: 12, md: 6 }}>
				<div class="slice-grid-text-image-item__image-wrap">
					<Img {...props.image} />
				</div>
			</Column>
		</Row>
	)
}
