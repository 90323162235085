import { h } from 'preact'
import { RichText, asText } from '../../lib/prismic-render'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'
import { Img } from '../../components/img'
import { Button } from '../../components/button'
import './banner-split.scss'

export function BannerSplit(props) {
	return (
		<div class="slice-banner-split">
			<Container>
				<Row>
					<Column class="slice-banner-split__content" size={{ xs: 12, md: 6 }}>
						<div class="slice-banner-split__headlines">
							{props.primary.heading && !!props.primary.heading.length && (
								<h2 class="slice-banner-split__heading">
									{asText(props.primary.heading)}
								</h2>
							)}

							{props.primary.text && !!props.primary.text.length && (
								<RichText richText={props.primary.text} />
							)}
						</div>
						{props.primary.link && props.primary.link_text && (
							<footer class="slice-banner-split__footer">
								<Button
									href={props.primary.link}
									large={true}
									invert={true}
									flat={true}
								>{props.primary.link_text}</Button>
							</footer>
						)}
					</Column>
					<Column class="slice-banner-split__image-wrap" size={{ xs: 12, md: 6 }}>
						<Img class="slice-banner-split__image" {...props.primary.image} />
					</Column>
				</Row>
			</Container>
		</div>
	)
}
