import { h } from 'preact'
import { useState, useRef, useCallback } from 'preact/hooks'
import { asText, RichText } from '../../lib/prismic-render'
import { Button } from '../../components/button'
import cx from 'clsx'
import './faq-item.scss'

export function FaqItem(props) {
	const outer = useRef(null)
	const inner = useRef(null)
	const [open, setOpen] = useState(false)
	const [height, setHeight] = useState(0)

	const handleTransitionEnd = useCallback(event => {
		event.target.removeEventListener('transitionend', handleTransitionEnd)
		setHeight('auto')
	}, [])

	const handleClick = useCallback(() => {
		if (open) {
			setOpen(false)
			setHeight(0)
		} else {
			setOpen(true)
			setHeight(`${inner.current.offsetHeight}px`)

			// FIXME handle browsers that don't support transitionend?
			// FIXME this doesn't work properly
			inner.current.addEventListener('transitionend', handleTransitionEnd)
		}
	}, [open])

	return (
		<div class={cx('faq-item', open && 'faq-item--open')}>
			<button class="faq-item__button" onClick={handleClick}>
				<p class="faq-item__heading">
					{asText(props.heading)}
				</p>
			</button>
			<div class="faq-item__panel" ref={outer} style={{ height }}>
				<div class="faq-item__content" ref={inner}>
					{/* TODO turn .copy into component */}
					<div class="copy">
						<RichText richText={props.text} />
					</div>

					{props.link && props.link_text && (
						<Button class="faq-item__cta" href={props.link}>{props.link_text}</Button>
					)}
				</div>
			</div>
		</div>
	)
}
