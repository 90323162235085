import { h } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import cx from 'clsx'
import { Container } from '../container'
import { Button } from '../button'
import { Link } from '../link'

import './cookie-bar.scss'

const KEY = 'catalyst_cookies_accepted'

export function CookieBar() {
	const [visible, setVisible] = useState(false)

	// TODO create useLocalStorage() hook and use JSON.stringify()
	useEffect(() => {
		if (localStorage[KEY] !== 'true') {
			setVisible(true)
		}
	}, [])

	useEffect(() => {
		localStorage[KEY] = visible ? 'false' : 'true'
	}, [visible])

	return (
		<div class={cx('cookie-bar', visible && 'cookie-bar--visible')}>
			<Container>
				<div class="cookie-bar__inner">
					<p class="cookie-bar__text h6">
						{/* TODO remove .h6 */}
						This website uses cookies for analysis, personalized content and advertisement.
						By using this website, you accept our <Link class="tdu-h" href="/TODO">Terms & Conditions</Link>.
					</p>
					<div class="cookie-bar__buttons">
						<Button flat invert href="/TODO">Legal Page</Button>
						<Button flat invert onClick={() => setVisible(false)}>Accept All Cookies</Button>
					</div>
				</div>
			</Container>
		</div>
	)
}
