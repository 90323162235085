import { h } from 'preact'
import { RichText } from '../../lib/prismic-render'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'

export function Quote(props) {
	return (
		<blockquote class="slice-quote">
			<Container>
				<Row>
					<Column size={{ xs: 12, md: 9 }} offset={{ md: 2 }}>
						{/* TODO component or mixin for h2 */}
						<div class="h2">
							<RichText richText={props.primary.quote} />
						</div>
					</Column>
				</Row>
			</Container>
		</blockquote>
	)
}
