import { h } from 'preact'
import { TextGrid } from './text'
import { TextImageGrid } from './text-image'
import { Smallprint } from './smallprint'
import { ResourceList } from './resource-list'

export function Grid(props) {
	switch (props.primary.style) {
		case 'text_image':
			return <TextImageGrid {...props} />
		case 'text_smallprint':
			return <Smallprint {...props} />
		case 'text_image_vertical':
			return <ResourceList {...props} />
		case 'text':
		case 'text_2_columns':
		case 'text_3_columns':
		default:
			return <TextGrid {...props} />
	}
}
