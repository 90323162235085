import { h, Fragment } from 'preact'
import { Switch, Route, useLocation } from 'wouter-preact'
import { useContent } from '../../lib/content'
import { Meta } from '../../components/meta'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'
import { PageContent } from '../../components/page'
import { RichText } from '../../lib/prismic-render'
import { ApplicationForm } from '../../components/forms/application-form'

export function Apply() {
	const [, go] = useLocation()
	const page = useContent({ type: 'application' })
	const { data } = page
	const { formstack_id, thank_you_message } = data

	return (
		<Fragment>
			<Meta page={page} />

			<Switch>
				<Route path="/apply/thank-you/:id?">
					<Container>
						<Row>
							<Column size={{ xs: 12, md: 8 }} offset={{ md: 3 }}>
								{thank_you_message && !!thank_you_message.length && (
									<article class="copy">
										<RichText richText={thank_you_message} />
									</article>
								)}
							</Column>
						</Row>
					</Container>
				</Route>
				<Route path="/apply/:sectionIndex?">
					<ApplicationForm formId={formstack_id} />
				</Route>
			</Switch>

			<PageContent page={page} />
		</Fragment>
	)
}
