import { h } from 'preact'
import { Link } from '../link'
import { useContent } from '../../lib/content'

import './dropdown.scss'
import { linkResolver } from '../../lib/link-resolver'

export function Dropdown() {
	const { data: settings } = useContent({ type: 'settings' })
	const schools = settings.schools || []

	return (
		<div class="dropdown">
			<div class="dropdown__title">Schools</div>
			<ul class="dropdown__items" style="background-color: var(--background-color)">
				{schools.map(item => (
					<li><Link class="dropdown__link--acting" href={linkResolver(item.page)}>{item.label}</Link></li>
				))}
			</ul>
		</div>
	)
}
