import { h } from 'preact'
import { asText, RichText } from '../../lib/prismic-render'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'
import { Img } from '../../components/img'
import { Embed } from '../../components/embed'

import './standard.scss'

export function HeaderStandard(props) {
	return (
		<header class="slice-header-standard">
			<Container>
				<div class="slice-header-standard__text">
					<Row>
						<Column size={{ xs: 12, md: 7 }} offset={{ md: 3 }}>
							<div class="copy">
								{/* TODO typography components */}
								{props.primary.heading && !!props.primary.heading.length && (
									<h2 class="h1">{asText(props.primary.heading)}</h2>
								)}
								{props.primary.text && !!props.primary.text.length && (
									<RichText richText={props.primary.text} />
								)}
							</div>
						</Column>
					</Row>
				</div>
				<div class="slice-header-standard__image">
					<Row>
						<Column size={{ xs: 12, md: 9 }} offset={{ md: 2 }}>
							{(props.primary.embed && props.primary.embed.html)
								? <Embed {...props.primary.embed} />
								: <Img {...props.primary.image} />
							}
						</Column>
					</Row>
				</div>
			</Container>
		</header>
	)
}
