import { h, Fragment } from 'preact'
import useSWR, { useSWRPages } from 'swr'
import { Meta } from '../../components/meta'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'
import { People } from '../../components/people'

function fetcher(url) {
	return fetch(url).then(res => res.json())
}

export function Team() {
	const { data, error } = useSWR(`/api/content/team`, fetcher)

	return (
		<Fragment>
			<Meta />
			<Container>
				<Row>
					<Column size={{ xs: 12, md: 10 }} offset={{ md: 2 }}>
						{data && data.results && (
							<People items={data.results} />
						)}
					</Column>
				</Row>
			</Container>
		</Fragment>
	)
}

// FIXME there seems to a preact incompatibility when using pagination

// export function Team() {
// 	const {
// 		pages,
// 		isLoadingMore,
// 		isReachingEnd,
// 		loadMore
// 	} = useSWRPages(
// 		'team',

// 		({ offset, withSWR }) => {
// 			const { data } = withSWR(
// 				useSWR(`/api/team?page=${offset || 1}`, fetcher)
// 			)

// 			if (!data) {
// 				return <p>loading...</p>
// 			}

// 			return <People items={data.results} />
// 		},

// 		({ data }) => {
// 			return data.next_page
// 				? data.page + 1
// 				: null
// 		},

// 		[]
// 	)

// 	return (
// 		<Container>
// 			<Row>
// 				<Column size={{ xs: 12, md: 10 }} offset={{ md: 2 }}>
// 					{pages}

// 					<button onClick={loadMore} disabled={isReachingEnd || isLoadingMore}>
// 						{isLoadingMore ? '. . .' : isReachingEnd ? 'no more data' : 'load more'}
// 					</button>
// 				</Column>
// 			</Row>
// 		</Container>
// 	)
// }
