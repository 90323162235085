import { h } from 'preact'
import { asText } from '../../lib/prismic-render'
import { Link } from '../link'
import { Img } from '../img'
import { Embed } from '../embed'

import './news-item.scss'

export function NewsItem(props) {
	if (props.data.microstory) {
		return <NewsItemMicroStory {...props} />
	}

	return <NewsItemStandard {...props} />
}

function NewsItemStandard(props) {
	if (!props || !props.data) {
		return null
	}

	const school = props.data.school &&
		props.data.school.uid

	return (
		<article class={`news-item news-item--standard ${school ? `news-item--${school}` : ''}`}>
			<Link href={props}>
				<Img {...props.data.cover_image} />
				<h5 class="news-item__title">
					{asText(props.data.title)}
				</h5>
			</Link>
		</article>
	)
}

function NewsItemMicroStory(props) {
	const slice = props.data.body.find(
		item => item.slice_type === 'embed'
	)

	if (!slice) {
		return null
	}

	const school = props.data.school &&
		props.data.school.uid

	return (
		<article class={`news-item news-item--microstory ${school ? `news-item--${school}` : ''}`}>
			{slice.primary.embed && slice.primary.embed.html && (
				<Embed {...slice.primary.embed} />
			)}
			<Link href={props}>
				<h5 class="news-item__title">
					{asText(props.data.title)}
				</h5>
			</Link>
		</article>
	)
}
