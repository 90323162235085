import { html } from "htm/preact";
import { Component, createRef, createContext } from "preact";
import { useState, useEffect, useMemo, useCallback, useRef } from "preact/hooks";
import * as idb from "idb-keyval";

function bool(value) {
	return Boolean(Number(value));
}

function encodeFile(file) {
	return new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.onerror = reject;
		reader.onload = event => {
			// Remove Data-URL declaration
			const [, contents] = event.target.result.split(",");
			resolve(file.name + ";" + contents);
		}
		reader.readAsDataURL(file);
	});
}

function getSections(form) {
	const sections = [{ section: null, fields: [] }];

	for (const field of form.fields) {
		let currentSection = sections[sections.length - 1];

		if (field.type === "section") {
			if (currentSection.section) {
				sections.push(currentSection = { section: field, fields: [] });
			} else {
				currentSection.section = field;
			}
		}

		currentSection.fields.push(field);
	}

	return sections;
}

// Keep track of files we're still encoding so that we can await them
// before serialising the form for sending off.
let taskQueue = [];

function enqeueueTask(promise) {
	taskQueue.push(promise);

	promise.then(() => {
		taskQueue.splice(taskQueue.indexOf(promise), 1);
	});
}

function pendingTasks() {
	return Promise.all(taskQueue);
}

const { Provider, Consumer } = createContext({});

export {
	Consumer as FormContextConsumer
};

export function FormContext({ id, children }) {
	const [{ loading, error }, setStatus] = useState({ loading: true, error: null });
	const [{ fields, sections }, setForm] = useState({});
	const [values, setValues] = useState({});
	const valuesRef = useRef(values);

	valuesRef.current = values;

	useEffect(async () => {
		setStatus({ loading: true, error: null });

		try {
			const response = await fetch(`/api/form/${id}`);

			if (!response.ok) {
				throw new Error("Could not fetch form.")
			}

			const form = await response.json();

			const { fields } = form;
			const sections = getSections(form);

			setForm({ fields, sections });
			setStatus({ loading: false, error: null });
		} catch (error) {
			console.error(error);
			setStatus({ loading: false, error });
		}

		try {
			const prevValues = JSON.parse(
				window.localStorage.getItem(`form_values_${id}`)
			) || {};
			setValues(prevValues);
		} catch (error) {
			console.error(error);
		}
	}, [id]);

	const saveValues = useCallback(() => {
		window.localStorage.setItem(`form_values_${id}`, JSON.stringify(valuesRef.current));
	}, [id]);

	const setValue = useCallback((name, value) => {
		setValues(prevValues => ({
			...prevValues,
			[name]: typeof value === "function"
				? value(prevValues[name])
				: value
		}));
	}, []);

	const serialize = useCallback(async () => {
		// Wait for files to save in IndexedDB
		await pendingTasks();

		const entries = await Promise.all(
			Object.entries(valuesRef.current)
				.map(([name, value]) => {
					// Formstack wants multiple checkbox values as a newline-delimited string
					// https://help.formstack.com/hc/en-us/articles/360019200272-Pre-Populating-Form-Fields
					// https://developers.formstack.com/discuss/5770ea967209040e005c08ef
					if (Array.isArray(value)) {
						return [name, value.join("\n")];
					} else if (typeof value === "object") {
						if (value.type === "file") {
							return idb.get(value.name)
								.then(file => [name, file])
								.catch(error => {
									console.error(error);
								});
						}
					} else {
						return [name, value];
					}
				})
		);

		const filtered = entries.filter(Boolean);
		const params = new URLSearchParams(filtered);
		const serialized = params.toString();

		return serialized;
	}, []);

	const ctx = {
		id,
		loading,
		error,
		fields,
		sections,
		values,
		setValues,
		setValue,
		saveValues,
		serialize
	};

	return html`
		<${Provider} value=${ctx}>
			${typeof children === "function"
				? children(ctx)
				: children
			}
		<//>
	`;
}

export function Form(props) {
	const {
		fields,
		values,
		setValue,
		setValues,
		saveValues,
		onSubmit,
		...otherProps
	} = props;

	const handleSubmit = event => {
		console.log("submit", event);

		onSubmit && onSubmit(event);
	}

	const handleInput = event => {
		console.log("input", event);

		let {
			name,
			value,
			type,
			files,
			checked
		} = event.target;

		switch (type) {
			case "file": {
				const [file, ] = files;

				if (file) {
					setValue(name, {
						type: "file",
						name: file.name
					});

					enqeueueTask(
						encodeFile(file)
							.then(data => {
								return idb.set(file.name, data);
							})
					);
				}
				break;
			}
			case "checkbox": {
				setValue(name, (prev = []) => {
					return checked
						? prev.includes(value)
							? value
							: prev.concat(value)
						: prev.includes(value)
							? prev.filter(v => v !== value)
							: prev;
				});
				break;
			}
			case "number":
			case "range": {
				const parsed = parseFloat(value);
				setValue(name, isNaN(parsed) ? '' : parsed);
				break;
			}
			default: {
				setValue(name, value);
			}
		}
	}

	const handleChange = event => {
		console.log("save", event);

		saveValues();
	}

	return html`
		<form onSubmit=${handleSubmit} onInput=${handleInput} onChange=${handleChange} ...${otherProps}>
			${fields.map(field => {
				const hidden = bool(field.hidden);
				const hideLabel = bool(field.hide_label);
				const readonly = bool(field.readonly);
				const required = bool(field.required);

				switch (field.type) {
					case "section":
					case "richtext": {
						return html`
							<div class="form-field copy">
								${field.section_heading && html`
									<h1>${field.section_heading}</h1>
								`}
								${field.section_text && html`
									<div dangerouslySetInnerHTML=${{ __html: field.section_text }} />
								`}
							</div>
						`;
					}
					case "text":
					case "number":
					case "email":
					case "phone": {
						const fieldType = field.type === "phone"
							? "tel"
							: field.type;

						return html`
							<div class="form-field floating">
								<input
									type=${fieldType}
									id="field_${field.id}"
									name="field_${field.id}"
									defaultValue=${values[`field_${field.id}`]}
									required=${required}
									placeholder=${field.placeholder || " "}
								/>
								${!hideLabel && field.label && html`
									<label class="label" for="field_${field.id}">${field.label}</label>
								`}
								${field.description && html`
									<p class="description">${field.description}</p>
								`}
							</div>
						`;
					}
					case "file": {
						const value = values[`field_${field.id}`];

						return html`
							<div class="form-field file-field">
								<input
									class="visually-hidden"
									type="file"
									id="field_${field.id}"
									name="field_${field.id}"
								/>
								<label class="label" for="field_${field.id}">
									${!hideLabel && field.label && html`
										<span>${field.label}</span>
									`}
									<span>${value && value.name || "Add file here"}</span>
									<span>${value && value.name ? "Replace" : ""}</span>
								</label>
								${field.description && html`
									<p class="description">${field.description}</p>
								`}
							</div>
						`;
					}
					case "textarea": {
						return html`
							<div class="form-field floating">
								<textarea
									id="field_${field.id}"
									name="field_${field.id}"
									defaultValue=${values[`field_${field.id}`]}
									required=${required}
									placeholder=${field.placeholder || " "}
								/>
								${!hideLabel && field.label && html`
									<label class="label" for="field_${field.id}">${field.label}</label>
								`}
								${field.description && html`
									<p class="description">${field.description}</p>
								`}
							</div>
						`;
					}
					case "select": {
						return html`
							<div class="form-field">
								${!hideLabel && field.label && html`
									<label class="label" for="field_${field.id}">${field.label}</label>
								`}
								<select class="select" id="field_${field.id}" name="field_${field.id}">
									${field.options.map(option => html`
										<option
											value=${option.value}
											selected=${option.value === values[`field_${field.id}`]}
										>${option.label}</option>
									`)}
								</select>
								${field.description && html`
									<p class="description">${field.description}</p>
								`}
							</div>
						`;
					}
					case "radio": {
						return html`
							<div class="form-field stacked">
								${!hideLabel && field.label && html`
									<label class="label">${field.label}</label>
								`}
								<div class="l-row">
									${field.options.map(option => html`
										<div class="radio-button l-col6 l-col4@md">
											<input
												class="visually-hidden"
												type="radio"
												id="field_${field.id}_${option.value}"
												name="field_${field.id}"
												value=${option.value}
												defaultChecked=${option.value === values[`field_${field.id}`]}
											/>
											<label for="field_${field.id}_${option.value}">${option.label}</label>
										</div>
									`)}
								</div>
								${field.description && html`
									<p class="description">${field.description}</p>
								`}
							</div>
						`;
					}
					case "checkbox": {
						const value = values[`field_${field.id}`] || [];

						return html`
							<div class="form-field stacked">
								${!hideLabel && field.label && html`
									<label class="label">${field.label}</label>
								`}
								<div class="l-row">
									${field.options.map(option => html`
										<div class="radio-button l-col6 l-col4@md">
											<input
												class="visually-hidden"
												type="checkbox"
												id="field_${field.id}_${option.value}"
												name="field_${field.id}"
												value=${option.value}
												defaultChecked=${value.includes(option.value)}
											/>
											<label for="field_${field.id}_${option.value}">${option.label}</label>
										</div>
									`)}
								</div>
								${field.description && html`
									<p class="description">${field.description}</p>
								`}
							</div>
						`;
					}
					case "name": {
						return html`
							${false && !hideLabel && field.label && html`
								<label class="f-label">${field.label}</label>
							`}
							<div class="l-row">
								<div class="form-field floating l-col12 l-col6@md">
									<input
										type="text"
										id="field_${field.id}[first]"
										name="field_${field.id}[first]"
										defaultValue=${values[`field_${field.id}[first]`]}
										placeholder=" "
										required=${required}
									/>
									<label class="label" for="field_${field.id}[first]">First name</label>
								</div>
								<div class="form-field floating l-col12 l-col6@md">
									<input
										type="text"
										id="field_${field.id}[last]"
										name="field_${field.id}[last]"
										defaultValue=${values[`field_${field.id}[last]`]}
										placeholder=" "
										required=${required}
									/>
									<label class="label" for="field_${field.id}[last]">Last name</label>
								</div>
							</div>
							${field.description && html`
								<p class="description">${field.description}</p>
							`}
						`;
					}
					case "address": {
						return html`
							${false && !hideLabel && field.label && html`
								<label class="f-label">${field.label}</label>
							`}
							<div class="l-row">
								<div class="form-field floating l-col12">
									<input
										type="text"
										id="field_${field.id}[address]"
										name="field_${field.id}[address]"
										defaultValue=${values[`field_${field.id}[address]`]}
										placeholder=" "
										required=${required}
									/>
									<label class="label" for="field_${field.id}[address]">Address</label>
								</div>
								<div class="form-field floating l-col12">
									<input
										type="text"
										id="field_${field.id}[address2]"
										name="field_${field.id}[address2]"
										defaultValue=${values[`field_${field.id}[address2]`]}
										placeholder=" "
										required=${required}
									/>
									<label class="label" for="field_${field.id}[address2]">Address 2</label>
								</div>
								<div class="form-field floating l-col12 l-col6@md">
									<input
										type="text"
										id="field_${field.id}[city]"
										name="field_${field.id}[city]"
										defaultValue=${values[`field_${field.id}[city]`]}
										placeholder=" "
										required=${required}
									/>
									<label class="label" for="field_${field.id}[city]">City</label>
								</div>
								<div class="form-field floating l-col12 l-col6@md">
									<input
										type="text"
										id="field_${field.id}[state]"
										name="field_${field.id}[state]"
										defaultValue=${values[`field_${field.id}[state]`]}
										placeholder=" "
										required=${required}
									/>
									<label class="label" for="field_${field.id}[state]">State</label>
								</div>
								<div class="form-field floating l-col12 l-col6@md">
									<input
										type="text"
										id="field_${field.id}[zip]"
										name="field_${field.id}[zip]"
										defaultValue=${values[`field_${field.id}[zip]`]}
										placeholder=" "
										required=${required}
									/>
									<label class="label" for="field_${field.id}[zip]">Zip</label>
								</div>
							</div>
							${field.description && html`
								<p class="description">${field.description}</p>
							`}
						`;
					}
				}
			})}
			<button class="button" type="submit">Submit</button>
		</form>
	`;
}
