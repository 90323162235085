import { h } from 'preact'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'
import { FaqItem } from './faq-item'

export function Faq(props) {
	return (
		<div class="slice-faq">
			<Container>
				<Row>
					<Column size={{ xs: 12, md: 8 }} offset={{ md: 3 }}>
						<Row>
							{props.items.map(item => (
								<Column size={{ xs: 12, md: 6 }}>
									<FaqItem {...item} />
								</Column>
							))}
						</Row>
					</Column>
				</Row>
			</Container>
		</div>
	)
}
