import { h } from 'preact'
import { anchorify } from '../../lib/anchorify'
import './anchor.scss'

export function Anchor(props) {
	const anchor = props.primary &&
		props.primary.anchor &&
		anchorify(props.primary.anchor)

	return (
		<div id={anchor} class="slice-anchor" />
	)
}
