import { h } from 'preact'
import { Container } from '../container'
import { Row, Column } from '../layout'
import { Link } from '../link'
import { Logo } from '../logo'
import { useContent } from '../../lib/content'

import './footer.scss'
import { linkResolver } from '../../lib/link-resolver'

export function Footer() {
	const { data: settings } = useContent({ type: 'settings' })

	const schoolsTitle = settings.footer_schools_title || 'Schools'
	const catalystTitle = settings.footer_catalyst_title || 'Catalyst'
	const servicesTitle = settings.footer_services_title || 'Services'
	const partnersTitle = settings.footer_partners_title || 'Partners'

	const schoolsLinks = settings.schools || []
	const catalystLinks = settings.footer_catalyst || []
	const servicesLinks = settings.footer_services || []
	const legalLinks = settings.footer_legal || []
	const partners = settings.footer_partners || []

	const address = settings.footer_address || ''

	return (
		<footer class="footer">
			<Container class="footer__container">
				<Row>
					<Column size={{ xs: 12, md: 3 }} offset={{ md: 2 }}>
						<div class="footer__logo">
							<Logo />
						</div>
					</Column>
				</Row>
				<Row>
					<Column size={{ xs: 12, md: 3 }} offset={{ md: 2 }}>
						<nav class="footer__section">
							<h3 class="footer__heading">{schoolsTitle}</h3>
							<ul class="footer__links">
								{schoolsLinks.map(item => (
									<li class="footer__link">
										<Link href={linkResolver(item.page)}>{item.label}</Link>
									</li>
								))}
							</ul>
						</nav>
					</Column>
					<Column size={{ xs: 12, md: 3 }}>
						<nav class="footer__section">
							<h3 class="footer__heading">{catalystTitle}</h3>
							<ul class="footer__links">
								{catalystLinks.map(item => (
									<li class="footer__link">
										<Link href={linkResolver(item.page)}>{item.label}</Link>
									</li>
								))}
							</ul>
						</nav>
					</Column>
					<Column size={{ xs: 12, md: 3 }}>
						<div class="footer__section">
							<h3 class="footer__heading">Stay in touch</h3>
							<p>Join our mailing list</p>
						</div>
					</Column>
				</Row>
				<Row>
					<Column size={{ xs: 12, md: 3 }} offset={{ md: 2 }}>
						<nav class="footer__section">
							<h3 class="footer__heading">{servicesTitle}</h3>
							<ul class="footer__links">
								{servicesLinks.map(item => (
									<li class="footer__link">
										<Link href={linkResolver(item.page)}>{item.label}</Link>
									</li>
								))}
							</ul>
						</nav>
					</Column>
					<Column size={{ xs: 12, md: 5 }}>
						<nav class="footer__section">
							<h3 class="footer__heading">{partnersTitle}</h3>
							<ul class="footer__links footer__icons">
								{partners.map(item => (
									<li class="footer__link footer__icon">
										<div class="footer__icon-inner">
											<img class="footer__icon-img" src={item.logo && item.logo.url} />
										</div>
									</li>
								))}
							</ul>
						</nav>
					</Column>
				</Row>
				<Row class="pb1 fp2">
					<Column size={{ xs: 12, md: 6 }} offset={{ md: 2 }} class="pb1">
						{address}
					</Column>
					<Column size={{ xs: 12, md: 3 }} class="pb1">
						{legalLinks.map(item => (
							<span class="pr1">
								<Link href={linkResolver(item.page)}>{item.label}</Link>
							</span>
						))}
					</Column>
				</Row>
			</Container>
		</footer>
	)
}
