import { h } from 'preact'
import { asText } from '../../lib/prismic-render'
import { Container } from '../container'
import { Row, Column } from '../layout'
import { Link } from '../link'
import { Img } from '../img'

import './header-article.scss'

export function ArticleHeader({ page }) {
	if (!page || !page.data) {
		return null
	}

	// TODO rename crops to lowercase
	// TODO picture element

	return (
		<div class="header-article">
			<Container>
				<Row class="header-article__image-container">
					<Column size={{ xs: 12, md: 9 }} offset={{ md: 2 }}>
						{page.data.cover_image && page.data.cover_image.Landscape && (
							<Img {...page.data.cover_image.Landscape} />
						)}
					</Column>
				</Row>
				<Row>
					<Column size={{ xs: 12, md: 8 }} offset={{ md: 3 }}>
						{page.data.title && (
							<h1 class="h2">
								{asText(page.data.title)}
							</h1>
						)}
						<p class="fp2">
							Posted{' '}
							{page.data.author && page.data.author.data && (
								<span>
									by{' '}
									<Link href={page.data.author}>
										{asText(page.data.author.data.name)}
									</Link>
								</span>
							)}
							{/* TODO format date nicely */}
							{' '}on {page.data.publication_date}{' '}
							{page.data.categories && !!page.data.categories.length && (
								<span>
									in{' '}
									{page.data.categories.map(item => item.category && item.category.data && (
										<Link href={item.category}>
											{asText(item.category.data.title)}
										</Link>
									))}
								</span>
							)}
						</p>
					</Column>
				</Row>
			</Container>
		</div>
	)
}
