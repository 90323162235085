import { h } from 'preact'
import cx from 'clsx';
import './container.scss';

export function Container({ children, bleed = false, class: className, ...props }) {
	return (
		<div {...props} class={cx("container", bleed && 'container--bleed', className)}>
			{children}
		</div>
	)
}
