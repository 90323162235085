import { h } from 'preact'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'

import './illustration.scss'

/**
 * NOTE
 *
 * SVGs with CSS animations via <img> tag are broken in Safari 13.1, so we're using <object> for now.
 *
 * <img class="slice-illustration__image--desktop" src={`/illustrations/${props.primary.style || 'music'}-desktop.svg`} />
 * <img class="slice-illustration__image--mobile" src={`/illustrations/${props.primary.style || 'music'}-mobile.svg`} />
 */

export function Illustration(props) {
	return (
		<figure class="slice-illustration">
			<Container>
				<Row>
					<Column size={{ xs: 12, md: 10 }} offset={{ md: 2 }}>
						<object class="slice-illustration__image--desktop" data={`/illustrations/${props.primary.style || 'music'}-desktop.svg`} type="image/svg+xml"></object>
						<object class="slice-illustration__image--mobile" data={`/illustrations/${props.primary.style || 'music'}-mobile.svg`} type="image/svg+xml"></object>
					</Column>
				</Row>
			</Container>
		</figure>
	)
}
