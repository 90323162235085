import { h, Fragment } from 'preact'
import { asText, RichText } from '../../lib/prismic-render'
import { linkResolver } from '../../lib/link-resolver'

import { Img } from '../img'
import { Link } from '../link'
import { SocialLinks, OtherLinks } from '../social-links'

export function PersonHeader({ page }) {
	if (!page) return null

	return (
		<div class="container mt5 mb5">
			<div class="x xw gutter">
				<div class="c12 md-co2 md-c4">
					<div class="x xw gutter">
						<div class="c12">
							{page.data.image && (
								<Img {...page.data.image} />
							)}
						</div>
						<div class="c12 md-co3 md-c9 my0-5">
							{page.data.links && (
								<Fragment>
									<OtherLinks links={page.data.links} />
									<SocialLinks links={page.data.links} />
								</Fragment>
							)}
						</div>
					</div>
				</div>
				<div class="c12 md-c5 copy">
					{page.data.name && (
						<h1 class="h2">{asText(page.data.name)}</h1>
					)}
					{page.data.byline && (
						<h5>{page.data.byline}</h5>
					)}
					{page.data.bio && (
						<RichText richText={page.data.bio} />
					)}
				</div>
			</div>
			{page.data.courses && !!page.data.courses.length && (
				<div class="x xw gutter my5">
					<div class="c12 md-co3 md-c8">
						<div class="x xw gutter">
							<div class="c12 mb1">
								<h2>Courses</h2>
							</div>
							{page.data.courses.map(course => (
								<div class="c6">
									<Link class="dib w100 tac h4 py2 px1 b2 br1 rise-h" href={linkResolver(course)}>
										{asText(course.data.title)}
									</Link>
								</div>
							))}
						</div>
					</div>
				</div>
			)}
		</div>
	)
}
