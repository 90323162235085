import { h } from 'preact'
import { asText, RichText } from '../../lib/prismic-render'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'
import { Button } from '../../components/button'

import './heading.scss'

export function Heading(props) {
	const { primary } = props
	const { style = 'split' } = primary

	const has_cta = props.primary.link && props.primary.link_text
	const has_subheading = props.primary.subheading && !!props.primary.subheading.length && !!props.primary.subheading[0].text

	if (style === 'stacked') {
		return (
			<header class="m-heading">
				<Container>
					<Row>
						<Column size={{ xs: 8, md: has_cta ? 5 : 8  }} offset={{ md: 3 }}>
							<RichText richText={props.primary.heading} />
						</Column>
						{has_cta && (
							<Column size={{ xs: 4, md: 3 }}>
								<div class="u-flex u-items-center u-justify-end">
									<Button href={props.primary.link}>
										{props.primary.link_text}
									</Button>
								</div>
							</Column>
						)}
					</Row>
					{has_subheading && (
						<div class="u-mt12">
							<Row>
								<Column size={{ xs: 12, md: 8 }} offset={{ md: 3 }}>
									<p class="fb">{asText(props.primary.subheading)}</p>
								</Column>
							</Row>
						</div>
					)}
				</Container>
			</header>
		)
	}

	return (
		<header class="m-heading">
			<Container>
				<Row>
					<Column size={{ xs: 8, md: (has_cta || has_subheading) ? 5 : 8 }} offset={{ md: 3 }}>
						<div class="copy">
							<RichText richText={props.primary.heading} />
						</div>
					</Column>
					{has_cta ? (
						<Column size={{ xs: 4, md: 3 }}>
							<div class="u-flex u-items-center u-justify-end">
								<Button href={props.primary.link}>
									{props.primary.link_text}
								</Button>
							</div>
						</Column>
					) : has_subheading && (
						<Column size={{ xs: 4, md: 3 }}>
							<p class="fb">{asText(props.primary.subheading)}</p>
						</Column>
					)}
				</Row>
			</Container>
		</header>
	)
}
