import { h } from 'preact'
import { RichText, asText } from '../../lib/prismic-render'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'
import { Img } from '../../components/img'
import { Button } from '../../components/button'

import './banner-testimonial.scss'

export function BannerTestimonial(props) {
	return (
		<div class="slice-banner-testimonial">
			<Container>
				<Row>
					<Column size={{ xs: 12, md: 4 }} offset={{ md: 2 }}>
						<div class="slice-banner-testimonial__image-wrap">
							<Img class="slice-banner-testimonial__image" {...props.primary.image} />
						</div>
					</Column>
					<Column class="slice-banner-testimonial__content" size={{ xs: 12, md: 5 }}>
						<div class="slice-banner-testimonial__headlines">
							<div class="slice-banner-testimonial__heading">
								{props.primary.heading && !!props.primary.heading.length && (
									<h2 class="slice-banner-testimonial__heading">
										{asText(props.primary.heading)}
									</h2>
								)}
							</div>

							{props.primary.text && !!props.primary.text.length && (
								<RichText richText={props.primary.text} />
							)}
						</div>

						{props.primary.link && props.primary.link_text && (
							<div class="slice-banner-testimonial__footer">
								<Button href={props.primary.link} large={true}>
									{props.primary.link_text}
								</Button>
							</div>
						)}
					</Column>
				</Row>
			</Container>
		</div>
	)
}
