import { h } from 'preact'
import Swiper from 'react-id-swiper'
import { asText } from '../../lib/prismic-render'
import { Container } from '../container'
import { Row, Column } from '../layout'
import { Logotype } from '../logo'
import { Img } from '../img'

import './header-home.scss'

export function HeaderHome(props) {
	return (
		<div class="header-home">
			<Container>
				<div class="header-home__inner">
					<div class="header-home__text-wrap">
						<div class="header-home__text">
							<Row>
								<Column size={{ xs: 12, md: 8 }}>
									<Logotype class="header-home__logo" />
								</Column>
							</Row>
							<Row>
								<Column size={{ xs: 12, md: 4 }} offset={{ md: 1 }}>
									{/* TODO typography component */}
									<p class="h3">{asText(props.slogan)}</p>
								</Column>
							</Row>
						</div>
					</div>
					<div class="header-home__carousel-wrap">
						<Row>
							<Column size={{ xs: 12, md: 7 }} offset={{ md: 5 }}>
								<Carousel items={props.gallery} />
							</Column>
						</Row>
					</div>
				</div>
			</Container>
		</div>
	)
}

function Carousel(props) {
	return (
		<div class="header-home__carousel">
			<Swiper autoplay={true} loop={true}>
				{props.items.map(item => (
					<div class="header-home__slide">
						<Img class="header-home__image" {...item.image} />
					</div>
				))}
			</Swiper>
		</div>
	)
}
