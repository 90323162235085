import { h } from 'preact'
import { RichText, asText } from '../../lib/prismic-render'
import { Img } from '../../components/img'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'
import { Button } from '../../components/button'

import './resource-list.scss'

export function ResourceList(props) {
	return (
		<Container class="slice-grid-resource-list">
			<Row>
				<Column size={{ xs: 12, md: 8 }} offset={{ md: 3 }}>
					{props.items.map(ResourceListItem)}
				</Column>
			</Row>
		</Container>
	)
}

function ResourceListItem(props) {
	return (
		<div class="slice-grid-resource-list-item">
			<Row>
				<Column class="slice-grid-resource-list-item__text copy" size={{ xs: 12, md: 6 }}>
					<h2 class="slice-grid-resource-list-item__heading">
						{asText(props.heading)}
					</h2>
					<div class="slice-grid-resource-list-item__body">
						<RichText richText={props.text} />

						{props.link && props.link_text && (
							<div class="slice-grid-resource-list-item__button-wrap">
								<Button href={props.link}>
									{props.link_text}
								</Button>
							</div>
						)}
					</div>
				</Column>
				<Column class="slice-grid-resource-list-item__image" size={{ xs: 12, md: 6 }}>
					<Img {...props.image} />
				</Column>
			</Row>
		</div>
	)
}
