import { h } from 'preact'
import { useState } from 'preact/hooks'
import cx from 'clsx'
import { prettyLink } from '../../lib/links'
import { Link } from '../../components/link'
import { asText, RichText } from '../../lib/prismic-render'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'

import './link-list.scss'

export function LinkList(props) {
	const [index, setIndex] = useState(0)

	if (!props || !props.items || !props.items.length) {
		return null
	}

	return (
		<div class="slice-link-list">
			<Container>
				{props.primary && props.primary.heading && !!props.primary.heading.length && (
					<Row>
						<Column size={{ xs: 12, md: 9 }} offset={{ md: 3 }}>
							<h2 class="slice-link-list__heading">
								{asText(props.primary.heading)}
							</h2>
						</Column>
					</Row>
				)}
				<Row>
					<Column size={{ xs: 12, md: 4 }} offset={{ md: 3 }}>
						<ul class="slice-link-list__list">
							{props.items.map((item, i) => (
								<li
									class={cx('slice-link-list__list-item', {
										['slice-link-list__list-item--active']: i === index
									})}
									onMouseEnter={() => setIndex(i)}
									onMouseLeave={() => setIndex(0)}
								>
									<Link href={item.link}>
										{prettyLink(item.link)}
									</Link>
									<div class="slice-link-list__description-mobile">
										<RichText richText={item.description} />
									</div>
								</li>
							))}
						</ul>
					</Column>
					<Column class="slice-link-list__descriptions" size={{ xs: 12, md: 4 }} offset={{ md: 1 }}>
						{props.items.map((item, i) => (
							<div class={cx('slice-link-list__description-desktop', {
								['slice-link-list__description-desktop--visible']: index === i
							})}>
								<RichText richText={item.description} />
							</div>
						))}
					</Column>
				</Row>
			</Container>
		</div>
	)
}
