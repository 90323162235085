import { h, Fragment } from 'preact'
import { useState } from 'preact/hooks'
import { useLocation } from 'wouter-preact'
import { useInView } from 'react-intersection-observer'
import { LogoSmall } from '../logo-new'
import { Link } from '../link'
import { useContent } from '../../lib/content'
import { linkResolver } from '../../lib/link-resolver'

import './nav-mobile.scss'

export function HeaderMobile() {
	const [location] = useLocation()
	const [open, setOpen] = useState(false)
	const [sentinelRef, sentinelInView] = useInView()

	const isOnHomePage = location === '/'
	const navVisible = !isOnHomePage || !sentinelInView

	return (
		<div class={`header-mobile ${navVisible ? 'header-mobile--nav-visible' : ''}`}>
			<div ref={sentinelRef} class="header-mobile__sentinel" />
			<TopBarMobile open={open} handleClick={() => setOpen(b => !b)} />
			<NavMobile open={open} handleLinkClick={() => setOpen(false)} />
		</div>
	)
}

function TopBarMobile(props) {
	return (
		<header class="nav-mobile psf t0 l0 w100" style="z-index: 101;">
			<div class="container" style="background-color: var(--background-color);">
				<div class="x xjb xac">
					<Link href="/">
						<LogoSmall style={{ display: 'block' }} />
					</Link>
					<button onClick={props.handleClick}>{props.open ? 'Close' : 'Menu'}</button>
				</div>
			</div>
		</header>
	)
}

function NavMobile(props) {
	const { data: settings } = useContent({ type: 'settings' })
	const schools = settings.schools || []
	const menuItems = settings.menu_mobile || []

	return (
		<nav class="nav-mobile psf t0 l0 w100" style={`z-index: 100; transition: transform ease 300ms; transform: ${props.open ? 'translateY(0)' : 'translateY(-110%)'}`}>
			<div style="background-color: var(--background-color); box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);">
				<div class="container">
					<div class="p2" style="margin-top: 60px;">
						<div class="x gutter">
							<div class="c6">
								<ul class="fp1" style="list-style: none; line-height: 2;">
									{schools.map(item => (
										<li><Link onClick={props.handleLinkClick} href={linkResolver(item.page)}>{item.label}</Link></li>
									))}
									<li><Link onClick={props.handleLinkClick} href="/apply">Apply</Link></li>
								</ul>
							</div>
							<div class="c6">
								<ul class="fp1" style="list-style: none; line-height: 2;">
									{menuItems.map(item => (
										<li>
											<Link onClick={props.handleLinkClick} href={linkResolver(item.page)}>{item.label}</Link>
										</li>
									))}
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
	)
}
