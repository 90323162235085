import { h } from 'preact'
import { asText } from '../../lib/prismic-render'
import { Button } from '../../components/button'

import './marquee.scss'

function MarqueSegment(props) {
	return (
		<div class="slice-marquee-segment">
			{/* TODO use component or mixin for h2 */}
			<p class="slice-marquee-segment__inner h2">
				<span class="slice-marquee-segment__text-wrap">
					{asText(props.primary.text)}
				</span>
				<Button large flat invert href={props.primary.link}>
					{props.primary.link_text}
				</Button>
			</p>
		</div>
	)
}

export function Marquee(props) {
	return (
		<div class="slice-marquee">
			<div class="slice-marquee__inner">
				<div class="slice-marquee__slider">
					<MarqueSegment {...props} />
					<MarqueSegment {...props} />
					<MarqueSegment {...props} />
				</div>
			</div>
		</div>
	)
}
