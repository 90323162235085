import { h } from 'preact'
import { asText } from '../../lib/prismic-render'
import { Img } from '../../components/img'

import './person.scss'

export function Person(props) {
	if (!props || !props.data) {
		return null
	}

	return (
		<div class="person">
			<div class="person__image-wrap">
				<Img class="person__image" sizeHint={30} {...props.data.image} />
			</div>
			<div class="person__text">
				<p class="person__name">
					{asText(props.data.name)}
				</p>
				{/* TODO use fp2 component or mixin */}
				<p class="person__byline fp2">
					{props.data.byline}
				</p>
			</div>
		</div>
	)
}
