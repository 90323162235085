import { h } from 'preact'
import { linkResolver } from '../../lib/link-resolver'
import { RichText, asText } from '../../lib/prismic-render'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'
import { Button } from '../../components/button'
import { Img } from '../../components/img'
import './text.scss'

export function TextGrid(props) {
	return (
		<div class="slice-grid-text">
			<Container>
				<Row>
					<Column size={{ xs: 12, md: 9 }} offset={{ md: 3 }}>
						<Row>
							{props.items.map(item => (
								<Column size={{ xs: 12, md: 6, lg: (props.primary.style === 'text_2_columns' ? 6 : 4) }}>
									<TextGridItem {...item} />
								</Column>
							))}
						</Row>
					</Column>
				</Row>
			</Container>
		</div>
	)
}

function TextGridItem(props) {
	return (
		<div class="slice-text-grid-item copy">
			{props.image && props.image.url && (
				<div class="slice-text-grid-item__image-wrap">
					<Img {...props.image} />
				</div>
			)}
			{/* TODO create component for .copy */}
			<h5 class="slice-text-grid-item__heading">
				{asText(props.heading, null)}
			</h5>
			{/* TODO create component for fp2 */}
			<div class="slice-text-grid-item__body f-p2">
				<RichText richText={props.text} />
			</div>
			{props.link && props.link_text && (
				<div>
					<Button href={props.link}>{props.link_text}</Button>
				</div>
			)}
		</div>
	)
}
