import { h, Fragment } from 'preact'
import classnames from 'clsx'
import { useInView } from 'react-intersection-observer'
import { Switch, Route } from 'wouter-preact'
import { Container } from '../container'
// import { Logo, Logotype, LogotypeSmall, Minilogo, Slash } from '../logo'
import { LogoSmall, LogoMusicSmall, LogoFilmSmall, LogoActingSmall  } from '../logo-new'
import { Link } from '../link'
import { Menu } from './menu'
import cx from 'clsx'

import './mininav.scss'

export function MiniNav() {
	const [ref, top] = useInView()

	return (
		<Fragment>
			<div class="mininav__sentinel" ref={ref} />
			<nav class={cx('mininav', {
				['mininav--visible']: !top
			})}>
				<Container>
					<div class="mininav__inner">
						<div class="mininav__left">
							<Link href="/">
								<LogoSmall style={{ display: 'block' }} />
							</Link>
							<Switch>
								<Route path="/music/:x*">
									<LogoMusicSmall />
								</Route>
								<Route path="/film/:x*">
									<LogoFilmSmall />
								</Route>
								<Route path="/acting/:x*">
									<LogoActingSmall />
								</Route>
							</Switch>
						</div>

						<Menu />
					</div>
				</Container>
			</nav>
		</Fragment>
	)
}
