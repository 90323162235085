import { h } from 'preact'
import { RichText } from '../../lib/prismic-render'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'

export function Text(props) {
	return (
		<div class="slice-text">
			<Container>
				<Row>
					<Column size={{ xs: 12, md: 8 }} offset={{ md: 3 }}>
						{/* TODO component for copy */}
						<div class="copy">
							<RichText richText={props.primary.text} />
						</div>
					</Column>
				</Row>
			</Container>
		</div>
	)
}
