import { h } from 'preact'
import { Switch, Route } from 'wouter-preact'
import { LogoLarge, LogoMusicLarge, LogoActingLarge, LogoFilmLarge } from '../logo-new'
import { Container } from '../container'
import { Link } from '../link'
import { Menu } from './menu'

import './header.scss'

export function Header() {
	return (
		<header class="header">
			<Container>
				<div class="header__inner">
					<div class="header__left">
						<Switch>
							<Route path="/" />
							<Route path="/:x*">
								<Link href="/">
									<LogoLarge />
								</Link>
								<Switch>
									<Route path="/music/:x*">
										<Link href="/music">
											<LogoMusicLarge />
										</Link>
									</Route>
									<Route path="/film/:x*">
										<Link href="/film">
											<LogoFilmLarge />
										</Link>
									</Route>
									<Route path="/acting/:x*">
										<Link href="/acting">
											<LogoActingLarge />
										</Link>
									</Route>
								</Switch>
							</Route>
						</Switch>
					</div>
					<Menu />
				</div>
			</Container>
		</header>
	)
}
