import { h } from 'preact'
import { Carousel } from './carousel'
import { List } from './list'

export function Gallery(props) {
	let Component

	switch (props.primary.style) {
		case 'list':
			Component = List
			break
		case 'carousel':
		case 'carousel_small':
		default:
			Component = Carousel
	}

	return <Component {...props} />
}

