import { h } from 'preact'
import { RichText, asText } from '../../lib/prismic-render'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'
import { Img } from '../../components/img'
import { Button } from '../../components/button'
import cx from 'clsx'
import './banner-standard.scss'

export function BannerStandard(props) {
	const centered = props.primary.style === 'centered'

	return (
		<Container class={cx('slice-banner-standard', {
			['slice-banner-standard--centered']: centered
		})}>
			<div class="slice-banner-standard__inner">
				<div class="slice-banner-standard__image-wrap">
					<Img class="slice-banner-standard__image" {...props.primary.image} />
					{props.primary.image_overlay && (
						<div class="slice-banner-standard__image-overlay" />
					)}
				</div>
				<div class="slice-banner-standard__content">
					<div class="slice-banner-standard__headlines">
						<Row>
							<Column size={{ xs: 12, md: centered ? 6: 4 }} offset={{ md: centered ? 3 : 1 }}>
								{props.primary.heading && !!props.primary.heading.length && (
									<h2 class="slice-banner-standard__heading h1">
										{asText(props.primary.heading)}
									</h2>
								)}

								{props.primary.text && !!props.primary.text.length && (
									<RichText richText={props.primary.text} />
								)}
							</Column>
						</Row>
					</div>
					{props.primary.link && props.primary.link_text && (
						<footer class="slice-banner-standard__footer">
							<Row>
								<Column size={{ xs: 12, md: centered ? 6: 4 }} offset={{ md: centered ? 3 : 1 }}>
									<Button
										href={props.primary.link}
										large={true}
										invert={true}
										flat={true}
									>{props.primary.link_text}</Button>
								</Column>
							</Row>
						</footer>
					)}
				</div>
			</div>
		</Container>
	)
}
