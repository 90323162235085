import { h } from 'preact'
import { useState, useEffect, useRef } from 'preact/hooks'
import { prismicEndpoint } from '../../config'
import { getApi } from '../../lib/prismic-api'
import { asText } from '../../lib/prismic-render'
import { Link } from '../../components/link'
import { Button } from '../../components/button'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'
import { SearchBar } from '../../components/searchbar'

import './search.scss'

export function Search(props) {
	const api = useRef()
	const [term, setTerm] = useState(null)
	const [{ results, loading, error }, setState] = useState({
		results: null,
		loading: false,
		error: null
	})

	useEffect(async () => {
		api.current = await getApi(prismicEndpoint)
	}, [])

	useEffect(async () => {
		if (term && api.current && !loading) {
			setState({
				results: null,
				loading: true,
				error: null
			})

			try {
				const response = await api.current.query(`[[fulltext(document, "${term}")]]`)

				setState({
					results: response.results,
					loading: false,
					error: null
				})
			} catch (error) {
				setState({
					results: null,
					loading: false,
					error
				})
			}
		}
	}, [term])

	return (
		<div class="slice-search">
			<Container>
				<Row>
					<Column size={{ xs: 12, md: 8 }} offset={{ md: 3 }}>
						<SearchBar
							placeholder="Search resources"
							onInput={event => setTerm(event.target.value)} />

						{props.items && !!props.items.length && (
							<div class="u-mt12 u-mt24@md">
								{props.items.map(item => (
									<Button class="u-mr12" onClick={() => setTerm(item.recommended_term)}>{item.recommended_term}</Button>
								))}
							</div>
						)}

						<ul class="slice-search__results">
							{results && results.map(item => (
								<li class="slice-search__result">
									<SearchResult item={item} />
								</li>
							))}
						</ul>
					</Column>
				</Row>
			</Container>
		</div>
	)
}

function SearchResult(props) {

	return (
		<Link class="slice-search-result" href={props.item}>
			{asText(props.item.data.title || props.item.data.name || [])}
			{!!props.item.data.meta_description && (
				<p class="fp2">{props.item.data.meta_description}</p>
			)}
		</Link>
	)
}
