import { h, Fragment } from 'preact'
import { useContent } from '../../lib/content'

import { Header } from '../../slices/header'
import { Heading } from '../../slices/heading'
import { Anchor } from '../../slices/anchor'
import { Text } from '../../slices/text'
import { Grid } from '../../slices/grid'
import { Image } from '../../slices/image'
import { Embed } from '../../slices/embed'
import { Quote } from '../../slices/quote'
import { Banner } from '../../slices/banner'
import { Gallery } from '../../slices/gallery'
import { Expander } from '../../slices/expander'
import { Marquee } from '../../slices/marquee'
import { Team } from '../../slices/team'
import { Tutors } from '../../slices/tutors'
import { Illustration } from '../../slices/illustration'
import { LinkList } from '../../slices/link-list'
import { Search } from '../../slices/search'

import { Meta } from '../meta'
import { ArticleHeader } from '../header-article'
import { PersonHeader } from '../header-person'

import { PageMenu } from './page-menu'

import './page.scss'

export function Page(props) {
	const page = useContent(props.selector)

	return (
		<div class="page-builder">
			<Meta page={page} />
			<PageHeader page={page} />
			<PageContent page={page} />
		</div>
	)
}

export function PageContent({ page }) {
	if (!page || !page.data) {
		return null
	}

	return (
		<Fragment>
			<PageMenu page={page} />
			<div class="page-builder__body">
				{page.data.body.map(slice => {
					switch (slice.slice_type) {
						case 'header':
							return <Header {...slice} />
						case 'heading':
							return <Heading {...slice} />
						case 'anchor':
							return <Anchor {...slice} />
						case 'text':
							return <Text {...slice} />
						case 'quote':
							return <Quote {...slice} />
						case 'image':
							return <Image {...slice} />
						case 'gallery':
							return <Gallery {...slice} />
						case 'embed':
							return <Embed {...slice} />
						case 'grid':
							return <Grid {...slice} />
						case 'banner':
							return <Banner {...slice} />
						case 'expander':
							return <Expander {...slice} />
						case 'marquee':
							return <Marquee {...slice} />
						case 'tutors':
							return <Tutors courses={page.data.courses} {...slice} />
						case 'team':
							// TODO remove team later
							return <Team {...slice} />
						case 'link_list':
							return <LinkList {...slice} />
						case 'search':
							return <Search {...slice} />
						// case 'courses':
						// 	return <Courses courses={page.data.courses} {...slice} />
						case 'illustration':
							return <Illustration {...slice} />
						default:
							return null
						}
				})}
			</div>
		</Fragment>
	)
}

function PageHeader({ page }) {
	if (!page) return null

	switch (page.type) {
		case 'person':
			return <PersonHeader page={page} />
		case 'article':
			return <ArticleHeader page={page} />
		default:
			return null
	}
}
