import { linkResolver } from './link-resolver'

export function prettyLink(link) {
	const resolved = link && linkResolver(link);
	const cleaned = resolved && resolved
		.replace(/^https?:\/\//, '')
		.replace(/^www\./, '')
		.replace(/\/$/, '');
	return cleaned;
}
