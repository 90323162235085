import { html } from "htm/preact";
import { Switch, Route, Link, useLocation } from "wouter-preact";
import { FormContext, Form } from "./form.js";

import "./form.scss";

export function ApplicationForm(props) {
	const { formId = '3765589' } = props

	return html`
		<${Route} path="/apply/:sectionIndex?">
			${({ sectionIndex }) => console.log({sectionIndex}) || html`
				<${FormContext} id=${formId}>
					${ctx => {
						const {
							id,
							loading,
							error,
							/*fields,*/
							sections,
							values,
							setValue,
							setValues,
							saveValues,
							serialize
						} = ctx;

						const [, go] = useLocation();

						if (loading) return html`
							<div class="l-container">
								<div class="l-row">
									<div class="l-col12 l-col8@md l-off3@md">
										<pre>Loading</pre>
									</div>
								</div>
							</div>
						`;

						const index = +sectionIndex || 0;
						const section = sections[index];
						const fields = section && section.fields;

						if (!fields) {
							return go(`/apply`);
						}

						async function handleSubmit(event) {
							event.preventDefault();

							if (index < (sections.length - 1)) {
								return go(`/apply/${index + 1}`);
							}

							const body = await serialize();

							fetch(`/api/form/${id}/submission`, {
								method: "POST",
								mode: "cors",
								cache: "no-cache",
								credentials: "include",
								headers: {
									"Accept": "application/json",
									"Content-Type": "application/x-www-form-urlencoded"
								},
								body
							});

							// TODO pick ID from `values`
							go(`/apply/thank-you/course-id`);
						}

						return html`
							<div class="l-container">
								<div class="l-row">
									<div class="l-col12 l-col2@md">
										<nav class="form-nav">
											<ol>
												${sections.map((item, i) => {
													const { section_heading } = item.section;
													const className = i < index
														? "past"
														: i === index
														? "current"
														: "future";

													return html`
														<li class=${className}>${(i < index)
															? html`<${Link} href="/apply/${i}">${section_heading}<//>`
															: html`<span>${section_heading}</span>`
														}</li>
													`
												})}
											</ol>
										</nav>
									</div>
									<div class="l-col12 l-col8@md l-off1@md">
										<${Form}
											fields=${fields}
											values=${values}
											setValue=${setValue}
											setValues=${setValues}
											saveValues=${saveValues}
											onSubmit=${handleSubmit}
											method="POST"
											action="/form/${id}/submission"
											enctype="application/x-www-form-urlencoded"
										/>
									</div>
								</div>
							</div>
						`
					}}
				<//>
			`}
		<//>
	`;
}
