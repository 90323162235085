import { h } from 'preact'
import './embed.scss'

export function Embed(props) {
	return (
		<div
			class="embed"
			style={`padding-bottom: ${props.height / props.width * 100}%`}
			dangerouslySetInnerHTML={{ __html: props.html }} />
	)
}
