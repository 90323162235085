import { h } from 'preact'

export function Plus({ rotate = false }) {
	return (
		<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M0 8H18V10H0V8Z" fill="currentColor"/>
			<path class={`plus ${rotate ? 'plus-rotate' : ''}`} d="M10 8.74228e-08L10 18H8L8 0L10 8.74228e-08Z" fill="currentColor"/>
		</svg>
	)
}

export function Twitter() {
	return (
		<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0ZM14.5822 12.7113L14.5508 12.1922C14.4563 10.847 15.2852 9.61824 16.5967 9.14158C17.0794 8.9721 17.8978 8.95091 18.4329 9.09921C18.6427 9.16276 19.0414 9.37461 19.3247 9.56528L19.8388 9.91483L20.4054 9.73476C20.7202 9.63943 21.1398 9.48054 21.3287 9.37461C21.5071 9.27928 21.6645 9.22632 21.6645 9.25809C21.6645 9.43817 21.2762 10.0525 20.951 10.3915C20.5103 10.8682 20.6362 10.9105 21.5281 10.5928C22.0632 10.4127 22.0737 10.4127 21.9687 10.6139C21.9058 10.7199 21.5805 11.0906 21.2343 11.4296C20.6467 12.0122 20.6152 12.0757 20.6152 12.563C20.6152 13.315 20.2585 14.8827 19.9018 15.7407C19.2408 17.3508 17.8243 19.0138 16.4079 19.8506C14.4144 21.0264 11.7598 21.323 9.52499 20.6345C8.78005 20.4014 7.5 19.8083 7.5 19.7023C7.5 19.6705 7.88821 19.6282 8.36036 19.6176C9.34663 19.5964 10.3329 19.321 11.1723 18.8337L11.7388 18.4948L11.0883 18.2723C10.165 17.9546 9.33613 17.2237 9.12629 16.5352C9.06334 16.3127 9.08432 16.3021 9.67188 16.3021L10.2804 16.2915L9.76631 16.0479C9.15777 15.7407 8.60168 15.2217 8.32888 14.6921C8.12953 14.3107 7.87772 13.3468 7.95116 13.2727C7.97215 13.2409 8.19248 13.3044 8.4443 13.3892C9.16826 13.654 9.26269 13.5904 8.843 13.1456C8.05609 12.3405 7.81477 11.1436 8.19248 10.0102L8.37085 9.50172L9.06334 10.1902C10.4798 11.5779 12.148 12.4041 14.0576 12.6477L14.5822 12.7113Z" fill="black"/>
		</svg>
	)
}

export function Instagram() {
	return (
		<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0ZM11.702 7.04826C12.5553 7.00943 12.828 6.99993 15.0007 6.99993H14.9982C17.1715 6.99993 17.4432 7.00943 18.2965 7.04826C19.1482 7.08726 19.7299 7.2221 20.2399 7.41993C20.7666 7.6241 21.2116 7.89744 21.6566 8.34244C22.1016 8.78711 22.3749 9.23345 22.5799 9.75962C22.7766 10.2683 22.9116 10.8496 22.9516 11.7013C22.9899 12.5547 22.9999 12.8273 22.9999 15C22.9999 17.1727 22.9899 17.4447 22.9516 18.298C22.9116 19.1494 22.7766 19.7309 22.5799 20.2397C22.3749 20.7657 22.1016 21.2121 21.6566 21.6567C21.2121 22.1017 20.7664 22.3758 20.2404 22.5801C19.7314 22.7779 19.1494 22.9128 18.2977 22.9518C17.4444 22.9906 17.1725 23.0001 14.9997 23.0001C12.8272 23.0001 12.5547 22.9906 11.7013 22.9518C10.8498 22.9128 10.2683 22.7779 9.75929 22.5801C9.23345 22.3758 8.78711 22.1017 8.34261 21.6567C7.89777 21.2121 7.62443 20.7657 7.41993 20.2396C7.22226 19.7309 7.08743 19.1496 7.04826 18.2979C7.00959 17.4445 6.99993 17.1727 6.99993 15C6.99993 12.8273 7.00993 12.5545 7.04809 11.7011C7.08643 10.8498 7.22143 10.2683 7.41976 9.75946C7.62477 9.23345 7.8981 8.78711 8.34311 8.34244C8.78778 7.8976 9.23412 7.62427 9.76029 7.41993C10.269 7.2221 10.8503 7.08726 11.702 7.04826Z" fill="black"/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M14.2821 8.4417C14.4215 8.44148 14.5714 8.44155 14.7332 8.44162L14.9998 8.4417C17.1358 8.4417 17.389 8.44936 18.2325 8.4877C19.0125 8.52336 19.4359 8.6537 19.7179 8.7632C20.0912 8.9082 20.3574 9.08154 20.6372 9.36154C20.9172 9.64154 21.0905 9.90821 21.2359 10.2815C21.3454 10.5632 21.4759 10.9866 21.5114 11.7666C21.5497 12.6099 21.558 12.8632 21.558 14.9983C21.558 17.1333 21.5497 17.3866 21.5114 18.23C21.4757 19.01 21.3454 19.4333 21.2359 19.715C21.0909 20.0883 20.9172 20.3542 20.6372 20.634C20.3572 20.914 20.0914 21.0873 19.7179 21.2323C19.4362 21.3423 19.0125 21.4723 18.2325 21.508C17.3892 21.5463 17.1358 21.5547 14.9998 21.5547C12.8636 21.5547 12.6104 21.5463 11.7671 21.508C10.9871 21.472 10.5638 21.3417 10.2816 21.2322C9.90825 21.0872 9.64159 20.9138 9.36158 20.6338C9.08158 20.3538 8.90824 20.0878 8.76291 19.7143C8.65341 19.4326 8.52291 19.0093 8.48741 18.2293C8.44907 17.386 8.44141 17.1326 8.44141 14.9963C8.44141 12.8599 8.44907 12.6079 8.48741 11.7646C8.52307 10.9846 8.65341 10.5612 8.76291 10.2792C8.90791 9.90588 9.08158 9.63921 9.36158 9.35921C9.64159 9.0792 9.90825 8.90587 10.2816 8.76053C10.5636 8.65053 10.9871 8.52053 11.7671 8.4847C12.5051 8.45136 12.7911 8.44136 14.2821 8.4397V8.4417ZM19.2702 9.77008C18.7402 9.77008 18.3102 10.1996 18.3102 10.7298C18.3102 11.2598 18.7402 11.6898 19.2702 11.6898C19.8002 11.6898 20.2302 11.2598 20.2302 10.7298C20.2302 10.1998 19.8002 9.76975 19.2702 9.76975V9.77008ZM10.8915 15.0001C10.8915 12.7313 12.7309 10.8918 14.9997 10.8917C17.2685 10.8917 19.1076 12.7312 19.1076 15.0001C19.1076 17.2689 17.2687 19.1076 14.9999 19.1076C12.731 19.1076 10.8915 17.2689 10.8915 15.0001Z" fill="black"/>
			<path d="M15.0007 12.3334C16.4734 12.3334 17.6674 13.5272 17.6674 15.0001C17.6674 16.4728 16.4734 17.6668 15.0007 17.6668C13.5278 17.6668 12.334 16.4728 12.334 15.0001C12.334 13.5272 13.5278 12.3334 15.0007 12.3334Z" fill="black"/>
		</svg>
	)
}

export function ArrowLeft() {
	return (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="39" y="39" width="38" height="38" rx="19" transform="rotate(-180 39 39)" stroke="black" stroke-width="2"/>
			<path d="M12.0702 19.2929C11.6797 19.6834 11.6797 20.3166 12.0702 20.7071L18.4342 27.0711C18.8247 27.4616 19.4579 27.4616 19.8484 27.0711C20.2389 26.6805 20.2389 26.0474 19.8484 25.6569L14.1916 20L19.8484 14.3431C20.2389 13.9526 20.2389 13.3195 19.8484 12.9289C19.4579 12.5384 18.8247 12.5384 18.4342 12.9289L12.0702 19.2929ZM27.7773 19L12.7773 19L12.7773 21L27.7773 21L27.7773 19Z" fill="black"/>
		</svg>
	);
}

export function ArrowRight() {
	return (
		<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="1" y="1" width="38" height="38" rx="19" stroke="black" stroke-width="2"/>
			<path d="M27.9298 20.7071C28.3203 20.3166 28.3203 19.6834 27.9298 19.2929L21.5658 12.9289C21.1753 12.5384 20.5421 12.5384 20.1516 12.9289C19.7611 13.3195 19.7611 13.9526 20.1516 14.3431L25.8084 20L20.1516 25.6569C19.7611 26.0474 19.7611 26.6805 20.1516 27.0711C20.5421 27.4616 21.1753 27.4616 21.5658 27.0711L27.9298 20.7071ZM12.2227 21L27.2227 21L27.2227 19L12.2227 19L12.2227 21Z" fill="black"/>
		</svg>
	);
}
