import { h } from 'preact'
import { useState, useEffect } from 'preact/hooks'
import { useInView } from 'react-intersection-observer'
import cx from 'clsx'
import './img.scss'

const widths = [512, 768, 1024, 2048, 4096]

export function Img({
	url,
	alt,
	lazy = true,
	sizeHint = 80,
	...props
}) {
	const src = url
	const srcset = widths.map(w => `${url}&q=80&w=${w} ${w}w`).join(', ')
	const sizes = [
		'(max-width: 599px) 100vw',
		`${100 * sizeHint / 100}vw`,
		`(min-width: 1480px) ${1480 * sizeHint / 100}px`
	].join(', ')

	if (lazy) {
		return (
			<LazyImg {...props} src={src} srcset={srcset} sizes={sizes} alt={alt} />
		)
	}

	return (
		<EagerImg {...props} src={src} srcset={srcset} sizes={sizes} alt={alt} />
	)
}

function EagerImg({
	class: className,
	src,
	srcset,
	sizes,
	alt,
	...props
}) {
	return (
		<img class={className} {...props} src={src} srcset={srcset} sizes={sizes} alt={alt} />
	)
}

function LazyImg({
	class: className,
	src,
	srcset,
	sizes,
	alt,
	dimensions,
	...props
}) {
	const [ref, visible] = useInView({
		rootMargin: '100%',
		triggerOnce: true
	})
	const [loaded, setLoaded] = useState(false)

	useEffect(() => {
		if (visible) {
			const image = new Image()
			image.onload = () => setLoaded(true)
			image.sizes = sizes
			image.srcset = srcset
			image.src = src
		}
	}, [visible, src, srcset, sizes])

	const aspect = dimensions
		? (dimensions.height || 1) / (dimensions.width || 1)
		: 1

	return (
		<div ref={ref} class={cx('lazy-img', className)} style={`--aspect: ${aspect * 100}%`}>
			{loaded ? (
				<img class="lazy-img__img" {...props} src={src} srcset={srcset} sizes={sizes} alt={alt} />
			) : (
				<img class="lazy-img__img" {...props} style="image-rendering: crisp-edges; image-rendering: pixelated;" src={`${src}&w=24`} alt={alt} />
			)}
		</div>
	)
}

// export function Picture({ Landscape, Square, Portrait, Social, ...props }) {
// 	const sources = [
// 		{
// 			media: '(min-width: 768px)',
// 			img: Landscape
// 		}
// 	]
// 	return html`
// 		<picture>
// 			<source>
// 			<${Img} ...${props} />
// 		</picture>
// 	`
// }
