import { h } from 'preact'
import { useState, useEffect, useRef } from 'preact/hooks'
import { useLocation, useRoute } from 'wouter-preact'
import { Helmet } from 'react-helmet-async'
import { useContent } from '../../lib/content'
import { prismicEndpoint } from '../../config'
import { getApi } from '../../lib/prismic-api'
import { asText } from '../../lib/prismic-render'
import { Container } from '../container'
import { Row, Column } from '../layout'
import { Button } from '../button'
import { SearchBar } from '../searchbar'
import { NewsItem } from './news-item'
import { useInView } from 'react-intersection-observer'

import './news.scss'

export function News(props) {
	const [, navigate] = useLocation()
	const [isSearch, searchParams] = useRoute('/stories/search/:term')
	const apiRef = useRef(null)
	const [{ page, hasMore }, setCursor] = useState({ page: 2, hasMore: true })
	const [loading, setLoading] = useState(false)
	const [sentinelRef, sentinelVisible] = useInView()
	let { schools, articles } = useContent(props.selector)
	const [allArticles, setAllArticles] = useState(articles)

	const { school } = props.selector
	const searchTerm = searchParams && searchParams.term

	// Reset when changing filters
	useEffect(() => {
		setAllArticles(articles)
		setCursor({ page: 2, hasMore: true })
	}, [school, searchTerm])

	// Lazy-load more articles when scrolling to the bottom
	// TODO sync this logic with queries.js
	useEffect(async () => {
		if (sentinelVisible && hasMore && !loading) {
			setLoading(true)

			const api = apiRef.current || (apiRef.current = await getApi(prismicEndpoint))

			const predicates = ['[[at(document.type, "article")]]']
			const opts = { page, orderings: '[my.article.publication_date desc]' }

			if (school === 'catalyst') {
				// articles without a school are general catalyst news
				// TODO should probably not have `/school` prefix
				predicates.push(`[[missing(my.article.school)]]`)
			} else if (school) {
				const schoolId = schools.find(item => item.uid === school).id
				predicates.push(`[[at(my.article.school, "${schoolId}")]]`)
			}

			const articlesResponse = await api.query(predicates, opts)

			setAllArticles(articles => articles.concat(articlesResponse.results))

			setCursor({
				page: page + 1,
				hasMore: !!articlesResponse.results.length && !!articlesResponse.next_page
			})

			setLoading(false)
		}
	}, [sentinelVisible, loading, page, school])

	// TODO add other meta tags for news too

	function handleSearchBarKeydown(event) {
		if (event.key === 'Enter') {
			const term = event.target.value
			const url = `/stories/search/${encodeURIComponent(term)}`
			navigate(url)
		}
	}

	return (
		<div class="news">
			<Helmet>
				<title>Stories | Catalyst</title>
			</Helmet>
			<Container>
				<header class="news__header">
					<Row>
						<Column size={{ xs: 12, md: 4 }} offset={{ md: 3 }}>
							<h1 class="news__heading">Read all about it</h1>
						</Column>
						<Column size={{ xs: 12, md: 4 }}>
							<p>The latest from Catalyst. From student work and events, to news and thought-pieces.</p>
						</Column>
					</Row>
				</header>
				<nav class="news__nav">
					<Row class="news__nav-row">
						<Column size={{ xs: 12, md: 8, xl: 4 }} offset={{ md: 3 }}>
							<ul class="news__nav-list">
								{schools.map(school => (
									<li class={`news__nav-item ${props.selector.school === school.uid ? 'news__nav-item--active' : ''}`}>
										<Button href={`/stories/school/${school.uid}`}>
											{asText(school.data.title)}
										</Button>
									</li>
								))}
								<li class={`news__nav-item ${props.selector.school === 'catalyst' ? 'news__nav-item--active' : ''}`}>
									<Button href={`/stories/school/catalyst`}>
										Catalyst
									</Button>
								</li>
							</ul>
						</Column>
						<Column size={{ xs: 12, md: 8, xl: 4 }} offset={{ md: 3, xl: 0 }}>
							<div class="news__search">
								<SearchBar placeholder="Search news" onKeydown={handleSearchBarKeydown} />
							</div>
						</Column>
					</Row>
				</nav>
				<div key={school || searchTerm} class="news__items">
					<Row>
						<Column size={{ xs: 12, md: 8 }} offset={{ md: 3 }}>
							<div class="news__items-wrap">
								{allArticles.map(NewsItem)}
							</div>
						</Column>
					</Row>
				</div>
				<div ref={sentinelRef} class="sentinel" />
			</Container>
		</div>
	)
}
