import { h } from 'preact'
import { useRef } from 'preact/hooks'
import Swiper from 'react-id-swiper'
import { asText, RichText } from '../../lib/prismic-render'
import { Img } from '../../components/img'
import { Link } from '../../components/link'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'
import { ArrowLeft, ArrowRight } from '../../components/icons'
import './carousel.scss'

const settingsSmall = {
	spaceBetween: 20,
	slidesPerView: 1,
	breakpoints: {
		600: {
			slidesPerView: 2
		},
	}
}

const settingsLarge = {
	spaceBetween: 20
}

export function Carousel(props) {
	const swiperRef = useRef();
	if (
		!props ||
		!props.primary ||
		!props.items ||
		!props.items.length
	) {
		return null
	}
	const settings = props.primary.style === 'carousel_small'
		? settingsSmall
		: settingsLarge

	function getSwiper(swiper) {
		swiperRef.current = swiper;
	}

	function handlePrevClick() {
		if (swiperRef.current) {
			swiperRef.current.slidePrev();
		}
	}

	function handleNextClick() {
		if (swiperRef.current) {
			swiperRef.current.slideNext();
		}
	}

	return (
		<div class="slice-gallery-carousel-wrapper">
			<Container>
				<Row>
					<Column size={{ xs: 12, md: 8 }} offset={{ md: 3 }}>
						<Swiper {...settings} getSwiper={getSwiper}>
							{props.items.map(item => (
								<div class="slice-gallery-carousel__slide">
									<CarouselSlide {...item} />
								</div>
							))}
						</Swiper>
					</Column>
				</Row>
			</Container>
			<Container class="slice-gallery-carousel__nav-container">
				<Row>
					<Column class="slice-gallery-carousel__nav-inner" size={{ xs: 3, md: 2 }} offset={{ xs: 9 }}>
						<CarouselNavigation
							onPrev={handlePrevClick}
							onNext={handleNextClick}
						/>
					</Column>
				</Row>
			</Container>
		</div>
	)
}

function CarouselNavigation(props) {
	return (
		<div class="carousel-navigation">
			<div class="carousel-navigation__prev">
				<button onClick={props.onPrev}>
					<ArrowLeft />
				</button>
			</div>
			<div class="carousel-navigation__next">
				<button onClick={props.onNext}>
					<ArrowRight />
				</button>
			</div>
		</div>
	)
}

function CarouselSlide(props) {
	return (
		<div class="slice-gallery-carousel-slide">
			<Img {...props.image} />
			{/* TODO create component for H5 */}
			{props.heading && !!props.heading.length && (
				<MaybeLink link={props.link}>
					<h5 class="h5 my0-5">{asText(props.heading)}</h5>
				</MaybeLink>
			)}
			{/* TODO create component for FP2 */}
			{props.description && !!props.description.length && (
				<MaybeLink link={props.link}>
					<div class="fp2 mt0-5">
						<RichText richText={props.description} />
					</div>
				</MaybeLink>
			)}
		</div>
	);
}

function MaybeLink({ children, link }) {
	const hasLink = link &&
		(
			link.link_type === 'Web' ||
			link.link_type === 'Document'
		)

	if (hasLink) {
		return (
			<Link href={link}>
				{children}
			</Link>
		)
	}

	return children
}
