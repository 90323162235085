import { h } from 'preact'
import cx from 'clsx'
import './row.scss'

export function Row({ class: className, gutter = true, children }) {
	return (
		<div class={cx('row', !gutter && 'row--no-gutter', className)}>
			{children}
		</div>
	)
}
