import { h } from 'preact'
import { useState, useRef, useCallback } from 'preact/hooks'
import { asText, RichText } from '../../lib/prismic-render'
import { Plus } from '../../components/icons'
import { Button } from '../../components/button'
import './accordion-row.scss'

// FIXME animate plus to minus on open

export function AccordionRow(props) {
	const outer = useRef(null)
	const inner = useRef(null)
	const [open, setOpen] = useState(false)
	const [height, setHeight] = useState(0)

	const handleTransitionEnd = useCallback(event => {
		event.target.removeEventListener('transitionend', handleTransitionEnd)
		setHeight('auto')
	}, [])

	const handleClick = useCallback(() => {
		if (open) {
			setOpen(false)
			setHeight(0)
		} else {
			setOpen(true)
			setHeight(`${inner.current.offsetHeight}px`)

			// FIXME handle browsers that don't support transitionend?
			// FIXME this doesn't work properly
			inner.current.addEventListener('transitionend', handleTransitionEnd)
		}
	}, [open])

	return (
		<div class="accordion-row">
			<button class="accordion-row__button h3" onClick={handleClick}>
				<h3>{asText(props.heading)}</h3>
				<Plus rotate={open} />
			</button>
			<div class="accordion-row__panel" ref={outer} style={{ height }}>
				<div class="accordion-row__content" ref={inner}>
					{/* TODO create component for .copy */}
					<div class="copy">
						<RichText richText={props.text} />
					</div>

					{props.link && props.link_text && (
						<Button class="accordion-row__cta" href={props.link}>{props.link_text}</Button>
					)}
				</div>
			</div>
		</div>
	)
}
