import { h } from 'preact'

import { BannerStandard } from '../banner-standard'
import { BannerSplit } from '../banner-split'
import { BannerSupport } from '../banner-support'
import { BannerTestimonial } from '../banner-testimonial'

export function Banner(props) {
	if (!props || !props.primary) {
		return null
	}

	switch (props.primary.style) {
		case 'split':
			return <BannerSplit {...props} />
		case 'support':
			return <BannerSupport {...props} />
		case 'testimonial':
			return <BannerTestimonial {...props} />
		case 'standard':
		case 'centered':
		default:
			return <BannerStandard {...props} />
	}
}
