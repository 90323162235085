import { h } from 'preact'
import cx from 'clsx'
import { Link } from '../link'

import './button.scss'

export function Button(props) {
	const {
		class: className,
		flat = false,
		large = false,
		invert = false,
		type: typeProp,
		...otherProps
	} = props

	const Component = props.href ? Link : 'button'

    const type = Component === 'button'
        ? typeProp || 'button'
		: undefined

    return (
		<Component
			{...otherProps}
			type={type}
			class={cx('button', {
				['button--flat']: flat,
				['button--large']: large,
				['button--invert']: invert,
			}, className)} />
    )
}
