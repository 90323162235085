import { h } from 'preact'
import { Container } from '../../components/container'
import { Row, Column } from '../../components/layout'
import { Embed as EmbedElement } from '../../components/embed'

export function Embed(props) {
	if (!props.primary || !props.primary.embed || !props.primary.embed.html) {
		return null
	}

	return (
		<figure class="slice-embed">
			<Container>
				<Row>
					<Column size={{ xs: 12, md: 8 }} offset={{ md: 3 }}>
						{props.primary.embed.type === 'video'
							? <EmbedElement {...props.primary.embed} />
							: <div dangerouslySetInnerHTML={{ __html: props.primary.embed.html }} />}
					</Column>
				</Row>
			</Container>
		</figure>
	)
}
