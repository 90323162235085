// TODO add array merge option to prismic-api
// TODO further investigate on how graphQuery and fetchLinks can be used together
const fetchLinks = [
	'topic.school',
	'course.school',
	'course.topic',
	'person.school',
	'person.name',
	'person.image',
	'person.links',
	'person.byline',
	'category.title'
].join(',')

export const routes = [
	{
		pattern: '/',
		queries: [
			api => api.getSingle('home', { fetchLinks })
		]
	},
	{
		pattern: '/apply',
		queries: [
			api => api.getSingle('application', { fetchLinks })
		]
	},
	{
		pattern: '/apply/:sectionIndex',
		queries: [
			api => api.getSingle('application', { fetchLinks })
		]
	},
	{
		pattern: '/apply/thank-you/:id?',
		queries: [
			api => api.getSingle('application', { fetchLinks })
		]
	},
	{
		pattern: '/payments/:uid',
		queries: [
			(api, params) => api.getByUID('payment', params.uid, { fetchLinks })
		]
	},
	{
		pattern: '/payments/:uid/thank-you',
		queries: [
			(api, params) => api.getByUID('payment', params.uid, { fetchLinks })
		]
	},
	{
		pattern: '/about',
		queries: [
			api => api.getSingle('about', { fetchLinks })
		]
	},
	{
		pattern: '/:school',
		queries: [
			(api, params) => api.getByUID('school', params.school, { fetchLinks })
		]
	},
	{
		pattern: '/:school/topic/:topic',
		queries: [
			(api, params) => api.getByUID('topic', params.topic, { fetchLinks })
		]
	},
	{
		pattern: '/:school/course/:course',
		queries: [
			(api, params) => api.getByUID('course', params.course, { fetchLinks })
		]
	},
	{
		pattern: '/pages/:page',
		queries: [
			(api, params) => api.getByUID('page', params.page, { fetchLinks })
		]
	},
	{
		pattern: '/stories',
		queries: [
			(api) => api.query('[[at(document.type, "school")]]', {
				graphQuery: `
					{
						school {
							title
						}
					}
				`
			}).then(schoolRes => {
				const predicates = '[[at(document.type, "article")]]'
				const opts = {
					orderings: '[my.article.publication_date desc]'
				}
				return api.query(predicates, opts).then(articlesRes => {
					return {
						type: 'news',
						schools: schoolRes.results,
						articles: articlesRes.results
					}
				})
			})
		]
	},
	{
		pattern: '/stories/search/:term',
		queries: [
			(api, params) => api.query('[[at(document.type, "school")]]', {
				graphQuery: `
					{
						school {
							title
						}
					}
				`
			}).then(schoolRes => {
				const predicates = [
					'[[at(document.type, "article")]]',
					`[[fulltext(document, "${decodeURIComponent(params.term)}")]]`
				]
				const opts = {
					orderings: '[my.article.publication_date desc]'
				}
				return api.query(predicates, opts).then(articlesRes => {
					return {
						type: 'news',
						term: params.term,
						schools: schoolRes.results,
						articles: articlesRes.results
					}
				})
			})
		]
	},
	{
		pattern: '/stories/school/:school',
		queries: [
			(api, params) => api.query('[[at(document.type, "school")]]', {
				graphQuery: `
					{
						school {
							title
						}
					}
				`
			}).then(schoolRes => {
				const predicates = [
					'[[at(document.type, "article")]]'
				]
				const opts = {
					orderings: '[my.article.publication_date desc]'
				}

				if (params.school === 'catalyst') {
					// articles without a school are general catalyst news
					// TODO should probably not have `/school` prefix
					predicates.push(`[[missing(my.article.school)]]`)
				} else {
					const schoolId = schoolRes.results
						.find(school => school.uid === params.school).id
					predicates.push(`[[at(my.article.school, "${schoolId}")]]`)
				}

				// TODO handle no results and wrong school id

				return api.query(predicates, opts).then(articlesRes => {
					return {
						type: 'news',
						school: params.school,
						schools: schoolRes.results,
						articles: articlesRes.results
					}
				})
			})
		]
	},
	{
		pattern: '/stories/:article',
		queries: [
			(api, params) => api.getByUID('article', params.article, { fetchLinks })
		]
	},
	{
		pattern: '/:school?/people/:person',
		queries: [
			// FIXME this is not nice, do it better
			(api, params) => api.getByUID('person', params.person, { fetchLinks })
				.then(doc => api.query(`[[at(my.course.tutors.tutor, "${doc.id}")]]`)
					.then(res => ({ ...doc, data: { ...doc.data, courses: res && res.results } })))
		]
	}
]
